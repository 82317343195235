import { DataGrid } from "@mui/x-data-grid";
import { useNavigation } from "@refinedev/core";
import { CreateButton, List, useDataGrid } from "@refinedev/mui";
import { couponCols } from "tables/couponCols";
import { Coupon } from "types/coupon";

export default function CouponList() {
  const { dataGridProps } = useDataGrid<Coupon>();
  const { show } = useNavigation();

  return (
    <List headerButtons={<CreateButton>Create Coupon</CreateButton>}>
      <DataGrid
        {...dataGridProps}
        columns={couponCols}
        autoHeight
        onRowClick={(params) => {
          show("coupons", params.row.id);
        }}
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </List>
  );
}
