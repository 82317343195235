export interface EventEntry {
  id: string;
  timestamp: Date;
  guestCount: number;
  status: EntryStatus;
  levelSufficient: boolean;
  walletKey: string;
}

export enum EntryStatus {
  OPEN = "OPEN",
  PERFORMED = "PERFORMED",
}
