import { Box } from "@mui/material";
import { HttpError } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import { Branch, defaultBranch, ofBranch } from "types/branch";

export default function EditBranch() {
  const methods = useForm<Branch, HttpError>({
    defaultValues: defaultBranch,
  });

  return (
    <Edit saveButtonProps={methods.saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <FormTextField
          name={ofBranch("name")}
          control={methods.control}
          label={"Name"}
          required
        />
        <FormTextField
          name={ofBranch("contact")}
          control={methods.control}
          label={"Contact email"}
          additionalRules={{
            pattern: {
              value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              message: "Invalid Email",
            },
          }}
        />
      </Box>
    </Edit>
  );
}
