import { GridColDef } from "@mui/x-data-grid";
import CurrencyAmount from "components/CurrencyAmount";
import { PublicKeyLink } from "components/PublicKeyLink";
import TokenImageLink from "components/TokenImageLink";
import { displayUSD } from "components/displayUSD";
import EditMenuItem from "components/table/EditMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import { Membership, ofMembership } from "types/membership";

export const membershipCols: GridColDef<Membership>[] = [
  {
    field: ofMembership("id"),
    headerName: "Id",
  },
  {
    field: ofMembership("imageUrl"),
    headerName: "Image",
    renderCell: function render({ row }) {
      return (
        <TokenImageLink
          mintAddress={row.membershipKey}
          fallbackImageUrl={row.imageUrl}
        />
      );
    },
    sortable: false,
    flex: 1,
  },
  {
    field: ofMembership("name"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofMembership("symbol"),
    headerName: "Symbol",
    flex: 1,
  },
  {
    field: ofMembership("level"),
    headerName: "Key2 Level",
    renderCell: function render({ row }) {
      return row.level || "-";
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: ofMembership("priceInUsdCents"),
    headerName: "Price",
    type: "number",
    renderCell: function render({ row }) {
      return displayUSD(row.priceInUsdCents ? row.priceInUsdCents / 100 : null);
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: ofMembership("amountOfCents"),
    headerName: "K2T Amount",
    renderCell: function render({ row }) {
      return <CurrencyAmount amountOfCents={row.amountOfCents} />;
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: ofMembership("mintCount"),
    headerName: "Mint Count",
    type: "number",
    flex: 1,
    minWidth: 100,
  },
  {
    field: ofMembership("membershipKey"),
    headerName: "Mint Key",
    renderCell: function render({ row }) {
      return <PublicKeyLink pubKey={row.membershipKey} type="address" />;
    },
    flex: 1,
    minWidth: 100,
    sortable: false,
  },
  {
    field: "menu",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <EditMenuItem resource={"memberships"} id={row.id} />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
