import { HttpError } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import React from "react";
import { FormProvider } from "react-hook-form";
import { defaultUser, User } from "types/user";
import UserForm from "./UserForm";

export default function EditUser() {
  const methods = useForm<User, HttpError>({
    defaultValues: defaultUser,
  });

  return (
    <Edit saveButtonProps={methods.saveButtonProps}>
      <FormProvider {...methods}>
        <UserForm />
      </FormProvider>
    </Edit>
  );
}
