import { TransactionStatus } from "./transaction";

export interface Airdrop {
  id: string;
  walletKey: string;
  status: TransactionStatus;
  transactionSignature: string;
  lastValidBlockHeight: number;
  createdAt: string;
  resolvedAt?: string;
  amount: number;
}

export interface CreateAirdrop {
  couponId: string | null;
  receiverWalletKey: string | null;
  amount: number;
}

export const defaultCreateAidrop: CreateAirdrop = {
  couponId: null,
  receiverWalletKey: null,
  amount: 1,
};
