import { Membership } from "./membership";

export interface Coupon {
  id: string;
  mintAddress: string;
  name: string;
  description?: string;
  image: { id: string; url: string } | null;
  validUntil?: string;
  externalUrl?: string;
}

export const defaultCoupon: Coupon = {
  id: "",
  mintAddress: "",
  name: "",
  externalUrl: "",
  description: "",
  image: null,
  validUntil: "",
};

export interface CouponRule {
  id: string;
  membership: Membership;
  coupon: Coupon;
  createdAt: string;
  active: boolean;
  airdropCount: number;
}

export interface CreateCouponRule {
  membershipId: number | null;
  couponId: string | null;
}

export const defaultCouponRule: CreateCouponRule = {
  membershipId: null,
  couponId: null,
};
