import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Button, MenuItem } from "@mui/material";
import { useAxios } from "provider/AxiosProvider";
import { downloadQrCode } from "utils/api/QrCodeApi";

export default function QrCodeDownloadMenuItem(props: {
  url: string;
  filename?: string;
}) {
  const axios = useAxios();
  return (
    <MenuItem onClick={() => downloadQrCode(axios, props.url, props.filename)}>
      <Button size="small" startIcon={<DownloadOutlinedIcon />}>
        Download QrCode
      </Button>
    </MenuItem>
  );
}
