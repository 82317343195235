import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { PublicConfigProvider } from "./provider/PublicConfigProvider";

console.info(
  `GIT_TAG: ${process.env.REACT_APP_GIT_TAG},` +
    ` GIT_COMMIT: ${process.env.REACT_APP_GIT_COMMIT}`
);

Sentry.init({
  dsn: "https://3505f136a3b64732900a8aa83fe0a0c4@o59532.ingest.sentry.io/4504830704222208",
  release: `key2-web@${process.env.REACT_APP_GIT_TAG}`,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeSend(event) {
    if (event) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

Sentry.configureScope((scope) => {
  scope.setTag("git_tag", process.env.REACT_APP_GIT_TAG || "undefined");
  scope.setTag("git_commit", process.env.REACT_APP_GIT_COMMIT || "undefined");
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PublicConfigProvider>
      {(config) => (
        <Auth0Provider
          domain={config.auth0Domain}
          clientId={config.auth0ClientId}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
          useRefreshTokens
          cacheLocation="localstorage"
        >
          <App />
        </Auth0Provider>
      )}
    </PublicConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
