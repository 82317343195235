import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { CrudFilters, HttpError, useExport, useList } from "@refinedev/core";
import { ExportButton } from "@refinedev/mui";
import { format } from "date-fns";
import { useBranch } from "provider/BranchProvider";
import { useState } from "react";
import { Membership } from "types/membership";
import { Redemption, RedemptionStatus } from "types/redemption";

export default function RedemptionFilter(props: {
  exportDisabled: boolean;
  filters: CrudFilters;
  setFilters: ((filters: CrudFilters) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
}) {
  const { exportDisabled, filters, setFilters } = props;
  const branch = useBranch();
  const [creationId, setCreationId] = useState<string>("");
  const [membership, setMembership] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<Date>();

  const { triggerExport } = useExport<Redemption>({
    pageSize: 100,
    filters: filters,
    mapData: (item) => {
      return {
        serial: item.serial,
        creationId: item.creationId,
        amountOfCents: item.amountOfCents,
        membership: item.membership.name,
        status: RedemptionStatus[item.status],
        createdAt: new Date(item.createdAt).toLocaleString(),
        redemptionUrl: item.qrCodeUrl,
      };
    },
    exportOptions: {
      filename: `redemptions-${branch?.name.toLowerCase()}`,
    },
  });

  const { data, isLoading } = useList<Membership, HttpError>({
    resource: "memberships",
    filters: [
      {
        field: "branchId",
        operator: "eq",
        value: branch?.id,
      },
    ],
  });

  const resetFilters = () => {
    setFilters([]);
    setCreationId("");
    setMembership("");
    setStatus("");
    setCreatedAt(undefined);
  };

  if (isLoading) return <></>;
  const memberships = data?.data;

  return (
    <Grid
      container
      display="flex"
      spacing={2}
      alignItems="flex-end"
      justifyContent="flex-start"
      marginBottom={1}
    >
      <Grid item>
        <TextField
          value={creationId}
          onChange={(e) => {
            setCreationId(e.target.value);
            setFilters((filters) => [
              {
                field: "creationId",
                value: e.target.value,
                operator: "eq",
              },
              ...filters,
            ]);
          }}
          label="Creation ID"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Membership</InputLabel>
          <Select
            label="Membership"
            variant="standard"
            value={membership}
            onChange={(e) => {
              setMembership(e.target.value);
              setFilters((filters) => [
                {
                  field: "membershipId",
                  value: e.target.value,
                  operator: "eq",
                },
                ...filters,
              ]);
            }}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value={""} key={""}>
              {"---"}
            </MenuItem>
            {memberships?.map((membership, i) => (
              <MenuItem key={i} value={membership.id}>
                {membership.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            variant="standard"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setFilters((filters) => [
                {
                  field: "status",
                  value: e.target.value,
                  operator: "eq",
                },
                ...filters,
              ]);
            }}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value={""} key={""}>
              {"---"}
            </MenuItem>
            {Object.values(RedemptionStatus).map((status, i) => (
              <MenuItem key={i} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <DatePicker
          label={"Created at"}
          format="dd/MM/yyyy"
          // @ts-ignore
          value={createdAt || null}
          onChange={(date: Date | null) => {
            if (date) {
              setCreatedAt(date);
              setFilters((filters) => [
                {
                  field: "createdAt",
                  value: format(date, "yyyy-MM-dd"),
                  operator: "eq",
                },
                ...filters,
              ]);
            }
          }}
          slotProps={{
            textField: {
              variant: "standard",
              inputProps: { readOnly: true },
              InputLabelProps: { shrink: true },
            },
          }}
        />
      </Grid>
      <Grid item>
        <Button onClick={resetFilters}>Reset Filter</Button>
      </Grid>
      <Grid item>
        <ExportButton
          onClick={triggerExport}
          variant="outlined"
          disabled={exportDisabled}
        />
      </Grid>
    </Grid>
  );
}
