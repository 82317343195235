import { AxiosInstance, CancelToken } from "axios";
import { apiHost } from "utils/rest/restApi";
const baseUri = apiHost + "/files";

export const uploadFile = async (
  axios: AxiosInstance,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
  cancelToken?: CancelToken
): Promise<UploadedFile> => {
  const form = new FormData();
  form.append("file", file, file.name || "");

  const response = await axios({
    method: "post",
    url: baseUri,
    data: form,
    cancelToken: cancelToken,
    onUploadProgress: onUploadProgress,
  });

  return response.data;
};

export const getFile = async (
  axios: AxiosInstance,
  fileId: string
): Promise<File> => {
  const response = await axios({
    responseType: "blob",
    method: "get",
    url: baseUri + `/${fileId}`,
    params: {
      size: "small",
    },
  });

  return response.data;
};

export interface UploadedFile {
  id: string;
  name: string;
  mediaType: string;
  url: string;
}
