import { DataGrid } from "@mui/x-data-grid";
import { useParsed } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { useConfig } from "provider/ConfigProvider";
import { paymentCols } from "tables/paymentCols";
import { EventPayment } from "types/payment";

export default function EventPayments() {
  const { id: eventId } = useParsed();
  const { currencyMintAddress } = useConfig();

  const { dataGridProps } = useDataGrid<EventPayment>({
    resource: "events/payments",
    filters: {
      permanent: [{ field: "eventId", operator: "eq", value: eventId }],
    },
  });

  return (
    <DataGrid
      {...dataGridProps}
      columns={paymentCols(currencyMintAddress)}
      autoHeight
      isRowSelectable={() => false}
    />
  );
}
