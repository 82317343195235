import { useAuth0 } from "@auth0/auth0-react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import InfoIcon from "@mui/icons-material/Info";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MoneyIcon from "@mui/icons-material/Money";
import PaidIcon from "@mui/icons-material/Paid";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Authenticated, ErrorComponent, Refine } from "@refinedev/core";
import {
  Layout,
  RefineSnackbarProvider,
  notificationProvider,
} from "@refinedev/mui";
import routerProvider, { NavigateToResource } from "@refinedev/react-router-v6";
import Title from "components/layout/Title";
import Header from "components/layout/header/Header";
import BranchList from "pages/branches/BranchList";
import CreateBranch from "pages/branches/CreateBranch";
import EditBranch from "pages/branches/EditBranch";
import CheckoutList from "pages/checkouts/CheckoutList";
import AirdropPage from "pages/coupons/airdrops/AirdropPage";
import CouponList from "pages/coupons/CouponList";
import CreateCoupon from "pages/coupons/CreateCoupon";
import CreateEvent from "pages/events/CreateEvent";
import EditEvent from "pages/events/EditEvent";
import EventList from "pages/events/EventList";
import EventStatistics from "pages/events/statistics/EventStatistics";
import { Login } from "pages/login";
import EditMembership from "pages/memberships/EditMembership";
import MembershipList from "pages/memberships/MembershipList";
import CreateMintRequest from "pages/mint-requests/CreateMintRequest";
import MintRequestList from "pages/mint-requests/MintRequestList";
import CreateRedemptions from "pages/redemptions/CreateRedemptions";
import RedemptionList from "pages/redemptions/RedemptionList";
import CreateUser from "pages/users/CreateUser";
import EditUser from "pages/users/EditUser";
import UserList from "pages/users/UserList";
import { createAccessControlProvider } from "provider/AccessControlProvider";
import { AccountProvider } from "provider/AccountProvider";
import { authProvider } from "provider/AuthProvider";
import { useAxios } from "provider/AxiosProvider";
import { BranchProvider } from "provider/BranchProvider";
import { ConfigProvider } from "provider/ConfigProvider";
import { dataProvider } from "provider/DataProvider";
import { MembershipProvider } from "provider/MembershipProvider";
import { Web3Provider } from "provider/Web3Provider";
import { Outlet, Route, Routes } from "react-router-dom";
import { useAxiosErrorHandling } from "utils/rest/useAxiosErrorHandling";
import InfoPage from "./pages/info/InfoPage";
import K2TStatsPage from "./pages/k2t-stats/K2TStatsPage";

export default function RefineWrapper() {
  const axios = useAxios();
  const { user, logout, getIdTokenClaims } = useAuth0();
  useAxiosErrorHandling(axios, logout);

  return (
    <RefineSnackbarProvider>
      <Refine
        dataProvider={dataProvider("/api", axios)}
        notificationProvider={notificationProvider}
        routerProvider={routerProvider}
        authProvider={authProvider(axios, user, logout, getIdTokenClaims)}
        accessControlProvider={createAccessControlProvider(axios)}
        resources={[
          {
            name: "mint-requests",
            list: "/mint-requests",
            create: "/mint-requests/create",
            icon: <ViewModuleIcon />,
          },
          {
            name: "redemptions",
            list: "/redemptions",
            create: "/redemptions/create",
            icon: <QrCode2Icon />,
          },
          {
            name: "events",
            list: "/events",
            create: "/events/create",
            edit: "/events/edit/:id",
            show: "/events/statistics/:id",
            icon: <CelebrationIcon />,
          },
          {
            name: "coupons",
            list: "/coupons",
            create: "/coupons/create",
            show: "/coupons/airdrops/:id",
            icon: <ConfirmationNumberIcon />,
          },
          {
            name: "admin",
            icon: <AdminPanelSettingsIcon />,
            meta: { label: "Admin" },
          },
          {
            name: "checkouts",
            list: "/checkouts",
            icon: <PaidIcon />,
            meta: {
              parent: "admin",
            },
          },
          {
            name: "memberships",
            list: "/memberships",
            edit: "/memberships/edit/:id",
            icon: <RememberMeIcon />,
            meta: {
              parent: "admin",
            },
          },
          {
            name: "branches",
            list: "/branches",
            create: "/branches/create",
            edit: "/branches/edit/:id",
            show: "/branches/:id/memberships",
            icon: <LocationCityIcon />,
            meta: {
              parent: "admin",
            },
          },
          {
            name: "users",
            list: "/users",
            create: "/users/create",
            edit: "/users/edit/:id",
            icon: <SupervisorAccountIcon />,
            meta: {
              parent: "admin",
            },
          },
          {
            name: "info",
            list: "/info",
            icon: <InfoIcon />,
            meta: {
              label: "Info",
              parent: "admin",
            },
          },
          {
            name: "k2t-stats",
            list: "/k2t-stats",
            icon: <MoneyIcon />,
            meta: {
              label: "K2T Stats",
              parent: "admin",
            },
          },
        ]}
      >
        <Routes>
          <Route
            element={
              <Authenticated fallback={<Outlet />}>
                <NavigateToResource resource="mint-requests" />
              </Authenticated>
            }
          >
            <Route path="/login" element={<Login />} />
          </Route>
          <Route
            element={
              <Authenticated redirectOnFail="/login">
                <AccountProvider>
                  <ConfigProvider>
                    <Web3Provider>
                      <BranchProvider>
                        <MembershipProvider>
                          <Layout Title={Title} Header={Header}>
                            <Outlet />
                          </Layout>
                        </MembershipProvider>
                      </BranchProvider>
                    </Web3Provider>
                  </ConfigProvider>
                </AccountProvider>
              </Authenticated>
            }
          >
            <Route
              index
              element={<NavigateToResource resource="mint-requests" />}
            />
            <Route path="/mint-requests">
              <Route index element={<MintRequestList />} />
              <Route path="create" element={<CreateMintRequest />} />
            </Route>
            <Route path="/redemptions">
              <Route index element={<RedemptionList />} />
              <Route path="create" element={<CreateRedemptions />} />
            </Route>
            <Route path="/events">
              <Route index element={<EventList />} />
              <Route path="create" element={<CreateEvent />} />
              <Route path="edit/:id" element={<EditEvent />} />
              <Route path="statistics/:id" element={<EventStatistics />} />
            </Route>
            <Route path="/coupons">
              <Route index element={<CouponList />} />
              <Route path="create" element={<CreateCoupon />} />
              <Route path="airdrops/:id" element={<AirdropPage />} />
            </Route>
            <Route path="/checkouts" element={<CheckoutList />} />
            <Route path="/memberships">
              <Route index element={<MembershipList />} />
              <Route path="edit/:id" element={<EditMembership />} />
            </Route>
            <Route path="/branches">
              <Route index element={<BranchList />} />
              <Route path="create" element={<CreateBranch />} />
              <Route path="edit/:id" element={<EditBranch />} />
            </Route>
            <Route path="/users">
              <Route index element={<UserList />} />
              <Route path="create" element={<CreateUser />} />
              <Route path="edit/:id" element={<EditUser />} />
            </Route>
            <Route path="/info" element={<InfoPage />} />
            <Route path="/k2t-stats" element={<K2TStatsPage />} />
            <Route path="*" element={<ErrorComponent />} />
          </Route>
        </Routes>
      </Refine>
    </RefineSnackbarProvider>
  );
}
