import React from "react";
import { Link } from "react-router-dom";
import { publicKeyToUrl, txKeyToUrl } from "./PublicKeyLink";
import { Stack } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useConfig } from "provider/ConfigProvider";

export function OpenInSolanaExplorerButton(props: {
  pubKey: string;
  type: "transaction" | "address";
  children?: React.ReactElement;
}) {
  const { solanaCluster } = useConfig();

  return (
    <Link
      to={
        props.type === "address"
          ? publicKeyToUrl(props.pubKey, solanaCluster)
          : txKeyToUrl(props.pubKey, solanaCluster)
      }
      target="_blank"
    >
      <Stack direction="row" alignItems="center" justifyItems="start" gap={1}>
        {props.children}
        <OpenInNewIcon sx={{ color: (theme) => theme.palette.text.primary }} />
      </Stack>
    </Link>
  );
}
