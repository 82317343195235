import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import { FormTextFieldProps } from "./FormTextField";

export default function FormDateField(
  props: FormTextFieldProps & {
    disablePast?: boolean;
    dateProps?: DateTimePickerProps<any>;
  }
) {
  const {
    name,
    control,
    required,
    label,
    additionalRules,
    disablePast,
    dateProps,
    ...remainingProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required || false,
          message: "This is required",
        },
        ...additionalRules,
      }}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <DateTimePicker
          label={label}
          disabled={props.disabled}
          {...dateProps}
          value={value ? new Date(value) : ""}
          {...field}
          slotProps={{
            textField: {
              error: !!error,
              variant: "standard",
              helperText: error?.message,
              margin: "normal",
              inputProps: { readOnly: true },
              InputLabelProps: { shrink: true },
              required: required,
              ...remainingProps,
            },
          }}
        />
      )}
    />
  );
}
