import { Button } from "@mui/material";
import { useLogout } from "@refinedev/core";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";

export default function LogoutButton() {
  const { mutate: logout } = useLogout();

  return (
    <Button
      sx={{ m: 1 }}
      variant="outlined"
      onClick={() => logout()}
      startIcon={<LogoutIcon />}
    >
      Logout
    </Button>
  );
}
