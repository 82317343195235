import { Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import CurrencyAmount from "components/CurrencyAmount";
import { PublicKeyLink } from "components/PublicKeyLink";
import { MembershipCell } from "components/table/MembershipCell";
import {
  MintRequest,
  MintRequestStatus,
  ofMintRequest,
} from "types/mint-request";

export const mintCols: GridColDef<MintRequest>[] = [
  {
    field: ofMintRequest("id"),
    headerName: "ID",
    hideable: true,
  },
  {
    field: ofMintRequest("serial"),
    headerName: "Serial",
    type: "number",
    hideable: true,
  },
  {
    field: " ",
    headerName: "Membership",
    flex: 1,
    sortable: false,
    renderCell: function render({ row }) {
      return (
        <MembershipCell
          membership={row.membership}
          mintAddress={row.mintAddress}
        />
      );
    },
    hideable: false,
  },
  {
    field: "amountOfCents",
    headerName: "K2T amount",
    flex: 1,
    hideable: false,
    renderCell: function render({ row }) {
      return <CurrencyAmount amountOfCents={row.amountOfCents} />;
    },
  },
  {
    field: "walletKey",
    headerName: "Wallet key",
    flex: 1,
    renderCell: function render({ row }) {
      return <PublicKeyLink pubKey={row.walletKey} type="address" />;
    },
    hideable: true,
    sortable: false,
  },
  {
    field: "createdBy",
    headerName: "Created by",
    flex: 1,
    sortable: false,
    renderCell: function render({ row }) {
      if (row.redemption) {
        return <span>Redemption {row.redemption?.id}</span>;
      }
      return <>{row.createdBy}</>;
    },
    hideable: true,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
    hideable: false,
    renderCell: function render({ row }) {
      return <span>{new Date(row.createdAt).toLocaleString()}</span>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: function render({ row }) {
      return (
        <Chip
          color={
            row.status === MintRequestStatus.SUCCESSFUL
              ? "success"
              : row.status === MintRequestStatus.FAILED
              ? "error"
              : row.status === MintRequestStatus.PENDING
              ? "info"
              : "default"
          }
          label={row.status}
          variant="outlined"
        />
      );
    },
    hideable: false,
    flex: 1,
  },
];
