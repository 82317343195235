export enum TransactionStatus {
  OPEN = "OPEN",
  SUCCEEDED = "SUCCEEDED",
  EXPIRED = "EXPIRED",
}

export interface Transfer {
  id: number;
  mintAddress: string;
  amount: number;
}
