import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useAxios } from "provider/AxiosProvider";
import { queryList } from "utils/rest/restApi";

export function useAllTagsQuery(): string[] {
  const axios = useAxios();

  const q = useQuery(["allTags"], () => getAllTags(axios));
  if (q.data === undefined) return [];
  return q.data;
}

export function getAllTags(axios: AxiosInstance): Promise<string[]> {
  return queryList<string>(axios, "tag").then((listRes) => {
    return listRes;
  });
}

export function getTimezones(axios: AxiosInstance): Promise<string[]> {
  return queryList<string>(axios, "events/timezones").then((timezones) => {
    return timezones;
  });
}
