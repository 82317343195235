import { HttpError, useList } from "@refinedev/core";
import UnexpectedError from "pages/UnexpectedError";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Branch } from "types/branch";
import { useAxios } from "./AxiosProvider";

export const BranchContext = createContext<BranchContextType>({} as any);

export interface BranchContextType {
  branch?: Branch;
  setBranch: (branch?: Branch) => void;
  branches: Branch[];
  refetchBranches: () => Promise<void>;
}

export const BranchProvider = (props: { children: ReactNode }) => {
  const [branch, setBranch] = useState<Branch | undefined>();
  const { data, refetch } = useList<Branch, HttpError>({
    resource: "branches/all",
  });
  const branches = data?.data || [];

  const axios = useAxios();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchBranches = useCallback(async () => {
    try {
      const { data: branches } = await refetch();
      const storageBranchId = localStorage.getItem("branchId");
      const storageBranch =
        storageBranchId &&
        branches?.data.find((b) => b.id.toString() === storageBranchId);
      if (storageBranch) {
        setBranch(storageBranch);
      }
      if (branches?.data.length === 1) {
        setBranch(branches.data[0]);
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  }, [refetch]);

  useEffect(() => {
    fetchBranches();
  }, [axios, fetchBranches]);

  const changeBranch = (branch?: Branch) => {
    setBranch(branch);
    if (branch) {
      localStorage.setItem("branchId", branch.id.toString());
    } else {
      localStorage.removeItem("branchId");
    }
  };

  if (loading || error) return <UnexpectedError loading={loading} />;

  return (
    <BranchContext.Provider
      value={{
        branch: branch,
        setBranch: changeBranch,
        branches: branches,
        refetchBranches: fetchBranches,
      }}
    >
      {props.children}
    </BranchContext.Provider>
  );
};

export function useBranchContext(): BranchContextType {
  return useContext(BranchContext);
}

export function useBranch(): Branch | undefined {
  return useContext(BranchContext).branch;
}

export function useBranches(): Branch[] {
  return useContext(BranchContext).branches;
}
