import { User } from "@auth0/auth0-react";
import { HttpError } from "@refinedev/core";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";
import { defaultUser } from "types/user";
import UserForm from "./UserForm";

export default function CreateUser() {
  const methods = useForm<User, HttpError>({
    defaultValues: defaultUser,
  });

  return (
    <Create saveButtonProps={methods.saveButtonProps}>
      <FormProvider {...methods}>
        <UserForm />
      </FormProvider>
    </Create>
  );
}
