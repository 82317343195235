import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PublicKeyLink } from "components/PublicKeyLink";
import TokenImageLink from "components/TokenImageLink";
import CurrencyAmount from "components/CurrencyAmount";
import TransactionStatusChip from "components/table/TransactionStatusChip";
import { EventPayment } from "types/payment";

export const paymentCols = (
  currencyMintAddress: string
): GridColDef<EventPayment>[] => [
  {
    field: "id",
    headerName: "Payment Id",
    flex: 1,
  },
  {
    field: "paid",
    headerName: "Paid",
    renderCell: function render({ row }) {
      const k2tTransfer = row.transfers.find(
        (transfer) => transfer.mintAddress === currencyMintAddress
      );
      if (k2tTransfer) {
        // If there is a K2T transfer, then it is a payment or token purchase
        return <CurrencyAmount amountOfCents={k2tTransfer.amount} />;
      } else if (row.transfers.length === 1)
        // If there is only one transfer that is not K2T transfer, then it is a token devaluation
        return <TokenImageLink mintAddress={row.transfers[0].mintAddress} />;
    },
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    renderCell: function render({ row }) {
      const k2tTransfer = row.transfers.find(
        (transfer) => transfer.mintAddress === currencyMintAddress
      );
      const otherTransfer = row.transfers.find(
        (transfer) =>
          transfer.mintAddress !== currencyMintAddress && transfer.amount - 1
      );
      if (otherTransfer && row.transfers.length > 1) {
        // If there is more than one transfer, then it is a token purchase
        return (
          <Grid display="flex" alignItems="center" gap={1}>
            <TokenImageLink mintAddress={otherTransfer.mintAddress} />
            <Typography sx={{ textDecoration: "none" }}>
              Ticket Purchase
            </Typography>
          </Grid>
        );
      } else if (k2tTransfer) {
        // If there is only one transfer and its a K2T transfer, then it is a payment
        return <Typography sx={{ textDecoration: "none" }}>Payment</Typography>;
      } else if (row.transfers.length === 1) {
        // If there is only one transfer that is not K2T transfer, then it is a token devaluation
        return (
          <Typography sx={{ textDecoration: "none" }}>
            Token Devaluation
          </Typography>
        );
      }
    },
    flex: 1,
  },
  {
    field: "transactionSignature",
    headerName: "Transaction",
    renderCell: function render({ row }) {
      return row.transactionSignature ? (
        <PublicKeyLink pubKey={row.transactionSignature} type="transaction" />
      ) : (
        <span>-</span>
      );
    },
    hideable: false,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Timestamp",
    renderCell: function render({ row }) {
      return <span>{new Date(row.createdAt).toLocaleString()}</span>;
    },
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: function render({ row }) {
      return <TransactionStatusChip status={row.status} />;
    },
  },
];
