import { LogoutOptions } from "@auth0/auth0-react";
import { HttpError } from "@refinedev/core";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { useEffect } from "react";

export function useAxiosErrorHandling(
  axios: AxiosInstance,
  logout: (options?: LogoutOptions | undefined) => void
) {
  useEffect(() => {
    const id = registerAxiosInterceptor(axios, logout);
    return () => {
      unregisterAxiosInterceptor(axios, id);
    };
  }, [axios, logout]);
}

export function registerAxiosInterceptor(
  axios: AxiosInstance,
  logout: (options?: LogoutOptions | undefined) => void
): number {
  return axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const status = error.response?.status || 500;

      let customError: HttpError = {
        ...error,
        message: error.response?.data,
        statusCode: status,
      };

      if (error.response && status === 401) {
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
      }

      return Promise.reject(customError);
    }
  );
}

export function unregisterAxiosInterceptor(axios: AxiosInstance, id: number) {
  axios.interceptors.response.eject(id);
}
