import { DataGrid } from "@mui/x-data-grid";
import { useParsed } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { entryCols } from "tables/entryCols";
import { EventEntry } from "types/entry";

export default function EventEntries() {
  const { id: eventId } = useParsed();

  const { dataGridProps } = useDataGrid<EventEntry>({
    resource: "events/entries",
    filters: {
      permanent: [{ field: "eventId", operator: "eq", value: eventId }],
    },
  });

  return (
    <DataGrid
      {...dataGridProps}
      columns={entryCols}
      autoHeight
      isRowSelectable={() => false}
    />
  );
}
