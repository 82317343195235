import { useLink } from "@refinedev/core";
import { Box } from "@mui/material";
import React from "react";

export default function Title() {
  const Link = useLink();

  return (
    <Box margin={6}>
      <Link to="/" sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            style={{ width: "100%" }}
            src={"/logo-light.png"}
            alt="logo"
          ></img>
        </Box>
      </Link>
    </Box>
  );
}
