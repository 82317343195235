import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useAllTagsQuery } from "utils/api/EventApi";

export function TagSection() {
  const availableTags = useAllTagsQuery();

  const { control } = useFormContext();

  const mayAddToTags = (
    event: any,
    field: ControllerRenderProps<FieldValues, "tags">
  ) => {
    const lowerCaseInput = event.target.value.toLocaleLowerCase().trim();
    if (lowerCaseInput !== "" && lowerCaseInput !== " ") {
      if (
        !field.value ||
        !field.value?.find(
          (element: string) => element.toLocaleLowerCase() === lowerCaseInput
        )
      ) {
        field.onChange([...field.value, event.target.value]);
      }
    }
  };
  return (
    <Controller
      name="tags"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          freeSolo
          multiple
          onBlur={(e) => {
            mayAddToTags(e, field);
          }}
          clearOnBlur
          autoHighlight={false}
          options={availableTags?.map((option) => option)}
          disableCloseOnSelect
          value={field.value}
          onChange={(e, value) => {
            field.onChange(value);
          }}
          isOptionEqualToValue={(option, value) =>
            option.toLocaleLowerCase() === value.toLocaleLowerCase()
          }
          getOptionLabel={(option) => {
            const correctTag = availableTags.find(
              (value) => value.toLowerCase() === option.toLowerCase()
            );
            return correctTag ? correctTag : option;
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Select tags"
              placeholder="Tags"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      )}
    />
  );
}
