import { FormControl, FormHelperText, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormTextFieldProps } from "./FormTextField";

export default function FormEditorField(props: FormTextFieldProps) {
  const { name, control, label, required, additionalRules } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required || false,
          message: "This is required",
        },
        ...additionalRules,
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal" sx={{ marginBottom: 4 }}>
          <Typography variant="subtitle2">{label}</Typography>
          <ReactQuill
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic"],
                [{ list: "ordered" }, { list: "bullet" }],
              ],
            }}
            formats={["header", "bold", "italic", "list"]}
            style={{ height: "200px" }}
            {...field}
          />
          <FormHelperText error>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
