import { User as Auth0User } from "@auth0/auth0-spa-js";
import { GridColDef } from "@mui/x-data-grid";
import DeleteMenuItem from "components/table/DeleteMenuItem";
import EditMenuItem from "components/table/EditMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import { Branch } from "types/branch";
import { User, getUserRoleLabel } from "types/user";

export const userCols = (
  branches: Branch[],
  user: Auth0User | undefined
): GridColDef<User>[] => [
  {
    field: "id",
    headerName: "Id",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "roles",
    headerName: "Roles",
    renderCell: function render({ row }) {
      return (
        <ul>
          {row.roles.map((role, i) => (
            <li key={i}>{getUserRoleLabel(role, branches)}</li>
          ))}
        </ul>
      );
    },
    flex: 1,
  },
  {
    field: " ",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <EditMenuItem resource="users" id={row.id} />
          <DeleteMenuItem
            resource="users"
            id={row.id}
            disabled={user?.email === row.email}
          />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
