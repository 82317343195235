import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { useDelete, useModal } from "@refinedev/core";
import { DeleteButton } from "@refinedev/mui";
import React from "react";

export default function DeleteMenuItem(props: {
  resource: string;
  id: string | number;
  disabled?: boolean;
}) {
  const { mutate } = useDelete();
  const { visible, close, show } = useModal();

  const handleDelete = () => {
    mutate({ resource: props.resource, id: props.id });
    close();
  };

  return (
    <>
      <MenuItem onClick={show} disabled={props.disabled}>
        <DeleteButton size="small" />
      </MenuItem>
      <Dialog
        open={visible}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={close}>Cancel</Button>
          <Button color="error" onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
