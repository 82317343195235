import { EntityRef } from "utils/rest/entities";
import { propertiesOf } from "../utils/propertyOf";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.MembershipDto] */
export interface Membership extends EntityRef {
  id: number;
  name: string;
  symbol: string;
  branchId: number;
  membershipKey: string;
  imageUrl: string;
  level: MembershipLevel | null;
  amountOfCents: number;
  priceInUsdCents: number | null;
  mintCount: number;
}

export const defaultMembership: Membership = {
  id: 0,
  name: "",
  symbol: "",
  branchId: 0,
  membershipKey: "",
  imageUrl: "",
  level: null,
  amountOfCents: 0,
  priceInUsdCents: null,
  mintCount: 0,
};

export enum MembershipLevel {
  SILVER = "SILVER",
  GOLD = "GOLD",
  ULTRA = "ULTRA",
  OBSIDIAN = "OBSIDIAN",
}

export function isLevelMembership(membership: Membership): boolean {
  return !!membership.level;
}

export const ofMembership = propertiesOf<Membership>();
