import { ReactElement, useEffect, useState } from "react";
import UnexpectedError from "../pages/UnexpectedError";
import { PublicConfig } from "../types/publicConfig";

export const PublicConfigProvider = (props: {
  children: (config: PublicConfig) => ReactElement;
}): ReactElement => {
  const [config, setConfig] = useState<PublicConfig>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch("/api/public/config")
      .then((res) => res.json())
      .then((config) => {
        setConfig(config);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);

  if (!config) return <UnexpectedError loading={loading} />;
  return props.children(config);
};
