import { Box, useTheme } from "@mui/material";
import DeleteMenuItem from "components/table/DeleteMenuItem";
import EditMenuItem from "components/table/EditMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import QrCodeDownloadMenuItem from "components/table/QrCodeDownloadMenuItem";
import ShowMenuItem from "components/table/ShowMenuItem";
import { useConfig } from "provider/ConfigProvider";
import QRCode from "react-qr-code";
import { Event } from "types/event";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

export const EventMenu = (props: { event: Event }) => {
  const { event } = props;
  const theme = useTheme();
  const { staffAppUrl } = useConfig();

  return (
    <Box position="absolute" top={theme.spacing(1)} right={theme.spacing(1)}>
      <MoreActionsButton>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: 8,
            marginBottom: 16,
          }}
        >
          <QRCode
            value={`${staffAppUrl}://event?code=${event.code}`}
            size={64}
          />
        </div>
        <QrCodeDownloadMenuItem
          url={`${staffAppUrl}://event?code=${event.code}`}
          filename={`${event.slug}-code.png`}
        />
        <ShowMenuItem
          resource="events"
          id={event.id}
          label={"Statistics"}
          startIcon={<AssessmentOutlinedIcon />}
        />
        <EditMenuItem resource="events" id={event.id} />
        <DeleteMenuItem resource="events" id={event.id} />
      </MoreActionsButton>
    </Box>
  );
};
