import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { Control, Controller } from "react-hook-form";

export type FormCheckboxFieldProps = {
  name: string;
  label: string;
  control: Control;
  helperText?: string;
};

export default function FormCheckboxField(props: FormCheckboxFieldProps) {
  const { name, label, control, helperText } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <>
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={label}
          />
          {helperText && (
            <FormHelperText sx={{ marginTop: 0 }}>{helperText}</FormHelperText>
          )}
        </>
      )}
    />
  );
}
