import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import FormTextField from "components/form/FormTextField";
import { useBranches } from "provider/BranchProvider";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getUserRoleLabel, UserRole } from "types/user";

export default function UserForm() {
  const { control, watch, register } = useFormContext();
  const branches = useBranches();

  useEffect(() => {
    register("roles");
  }, [register]);

  const rolesWatch = watch("roles");

  const allRoles = branches
    .map((branch) => `${UserRole.BRANCH_MANAGER}$${branch.abbreviation}`)
    .concat(
      Object.keys(UserRole).filter((role) => role !== UserRole.BRANCH_MANAGER)
    );

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <FormTextField
        name={"email"}
        control={control}
        label={"Email"}
        additionalRules={{
          pattern: {
            value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            message: "Invalid Email",
          },
        }}
        required
      />
      <Controller
        control={control}
        name={"roles"}
        defaultValue={rolesWatch}
        rules={{
          required: {
            value: true,
            message: "User must have at least one role",
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            value={field.value}
            multiple
            options={allRoles}
            getOptionLabel={(role: string) => getUserRoleLabel(role, branches)}
            onChange={(e, value) => field.onChange(value)}
            isOptionEqualToValue={(option, role) => option === role}
            renderOption={(props, role, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                {getUserRoleLabel(role, branches)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                label={"Roles"}
                required
                error={!!error}
                helperText={error?.message}
                margin="normal"
              />
            )}
          />
        )}
      />
    </Box>
  );
}
