import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { HttpError } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { Event, defaultEvent } from "types/event";
import { dateWithoutTimezone } from "utils/dateUtils";
import EventButtons from "./EventButtons";
import EventPreview from "./form/EventPreview";
import BasicPage from "./form/pages/BasicPage";
import DetailPage from "./form/pages/DetailPage";
import PublishPage from "./form/pages/PublishPage";
import TicketsPage from "./form/pages/TicketsPage";
import { getUtcEventDate } from "utils/eventUtils";

type EventPages = "basicinfo" | "details" | "tickets" | "publish";

export default function EditEvent() {
  const methods = useForm<Event, HttpError>({
    defaultValues: defaultEvent,
    mode: "onBlur",
  });
  const [selectedTab, setSelectedTab] = useState<EventPages>("basicinfo");

  const {
    handleSubmit,
    refineCore: { onFinish },
  } = methods;

  const handleSave = async (e: React.BaseSyntheticEvent, draft: boolean) => {
    await handleSubmit((values) =>
      onFinish({
        ...values,
        date: getUtcEventDate(values.date),
        publishAt: values.publishAt && dateWithoutTimezone(values.publishAt),
        draft: draft,
      })
    )(e);
  };

  return (
    <FormProvider {...methods}>
      <Edit
        headerButtons={({ defaultButtons }) => (
          <>
            {defaultButtons}
            <EventPreview />
          </>
        )}
        footerButtons={() => <EventButtons handleSave={handleSave} />}
      >
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(_, value) => setSelectedTab(value)}
              aria-label=""
            >
              <Tab label={"Basic Info"} value="basicinfo" />
              <Tab label={"Details"} value="details" />
              <Tab label={"Tickets"} value="tickets" />
              <Tab label={"Publish"} value="publish" />
            </TabList>
          </Box>
          <TabPanel value="basicinfo">
            <BasicPage />
          </TabPanel>
          <TabPanel value="details">
            <DetailPage />
          </TabPanel>
          <TabPanel value="tickets">
            <TicketsPage />
          </TabPanel>
          <TabPanel value="publish">
            <PublishPage />
          </TabPanel>
        </TabContext>
      </Edit>
    </FormProvider>
  );
}
