import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Account } from "types/account";
import { getAccount } from "utils/api/AccountApi";
import { useAxios } from "./AxiosProvider";
import NoPermissionsError from "pages/NoPermissionsError";
import UnexpectedError from "pages/UnexpectedError";

export const AccountContext = createContext<Account>({} as any);

export const AccountProvider = (props: { children: ReactNode }) => {
  const [account, setAccount] = useState<Account>();
  const axios = useAxios();
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState(false);

  useEffect(() => {
    getAccount(axios)
      .then((account) => {
        if (account.roles[0] !== "") setRoles(true);
        setAccount(account);
      })
      .catch(() => {
        console.log("Unexpected Error");
      })
      .finally(() => setLoading(false));
  }, [axios]);

  if (!account) return <UnexpectedError loading={loading} />;
  else if (!roles) return <NoPermissionsError user={account?.email} />;
  else
    return (
      <AccountContext.Provider value={account}>
        {props.children}
      </AccountContext.Provider>
    );
};

export function useAccount(): Account {
  return useContext(AccountContext);
}
