import CachedIcon from "@mui/icons-material/Cached";
import ListIcon from "@mui/icons-material/List";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useModal, useUpdate } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { useAxios } from "provider/AxiosProvider";
import { useState } from "react";
import { airdropCols } from "tables/airdropCols";
import { Airdrop } from "types/airdrop";
import { CouponRule } from "types/coupon";
import { airdropCouponsByRule } from "utils/api/AirdropApi";

export default function CouponRuleAirdropDialog(props: {
  couponRule: CouponRule;
}) {
  const { couponRule } = props;
  const modal = useModal();
  const {
    dataGridProps,
    tableQueryResult: { refetch },
  } = useDataGrid<Airdrop>({
    resource: "airdrops/rules/" + couponRule.id,
    queryOptions: { refetchInterval: 3000, refetchIntervalInBackground: false },
  });
  const [pending, setPending] = useState(false);
  const axios = useAxios();

  const refreshAirdrops = async () => {
    setPending(true);
    await airdropCouponsByRule(axios, couponRule.id);
    await refetch();
    setPending(false);
  };

  const { mutate } = useUpdate();

  const retryAirdrop = (airdropId: string) => {
    mutate({ resource: "airdrops", id: airdropId, values: {} });
  };

  return (
    <>
      <IconButton size="small" onClick={() => modal.show()}>
        <ListIcon />
      </IconButton>
      <Dialog
        open={modal.visible}
        onClose={() => modal.close()}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Airdrops</DialogTitle>
        <Chip
          sx={{
            position: "absolute",
            right: 24,
            top: 16,
          }}
          color={couponRule.active ? "success" : "warning"}
          label={`Distribution ${couponRule.active ? "Active" : "Inactive"}`}
        />
        <DialogContent>
          <DataGrid
            {...dataGridProps}
            columns={airdropCols(retryAirdrop)}
            autoHeight
            sx={{
              ...dataGridProps.sx,
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => modal.close()}>Close</Button>
          <LoadingButton
            variant="contained"
            startIcon={<CachedIcon />}
            loading={pending}
            onClick={() => refreshAirdrops()}
          >
            Refresh
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
