import { Button, ButtonProps, MenuItem } from "@mui/material";
import { useNavigation } from "@refinedev/core";

export default function ShowMenuItem(
  props: {
    resource: string;
    id: string | number;
    label: string;
    disabled?: boolean;
  } & ButtonProps
) {
  const { resource, id, label, disabled, ...buttonProps } = props;
  const { show } = useNavigation();

  return (
    <MenuItem
      onClick={() => show(props.resource, props.id)}
      disabled={props.disabled}
    >
      <Button size="small" {...buttonProps}>
        {label}
      </Button>
    </MenuItem>
  );
}
