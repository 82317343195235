import { MenuItem, Button, CircularProgress, Theme, Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { MembershipCell } from "components/table/MembershipCell";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import QrCodeDownloadMenuItem from "components/table/QrCodeDownloadMenuItem";
import { RedemptionImage } from "components/table/RedemptionImage";
import QRCode from "react-qr-code";
import { Redemption, ofRedemption, RedemptionStatus } from "types/redemption";
import CachedIcon from "@mui/icons-material/Cached";

export const redemptionCols = (
  theme: Theme,
  refreshing: boolean,
  refreshRedemption: (code: string) => void
): GridColDef<Redemption>[] => [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
  },
  {
    field: "creationId",
    headerName: "Creation ID",
    flex: 1,
    sortable: false,
  },
  {
    field: ofRedemption("serial"),
    headerName: "Serial",
    type: "number",
    filterable: false,
  },
  {
    field: "membership",
    headerName: "Membership",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <MembershipCell
          membership={row.membership}
          mintAddress={row.mintAddress}
        />
      );
    },
  },
  {
    field: "amountOfCents",
    headerName: "K2T Amount",
    flex: 1,
    filterable: false,
    renderCell: function render({ row }) {
      return (
        <div style={{ display: "flex", alignContent: "center" }}>
          <img
            src="k2t-coin.png"
            alt="K2T-Coin"
            style={{
              width: theme.spacing(3),
              marginRight: theme.spacing(1),
            }}
          />
          <span style={{ alignSelf: "center" }}>
            {(row.amountOfCents / 100).toLocaleString("en-US")}
          </span>
        </div>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
    renderCell: function render({ row }) {
      return <span>{new Date(row.createdAt).toLocaleString()}</span>;
    },
  },
  {
    field: "redeemedAt",
    headerName: "Redeemed at",
    filterable: false,
    flex: 1,
    renderCell: function render({ row }) {
      return row.redeemedAt ? (
        <>{new Date(row.redeemedAt).toLocaleString()}</>
      ) : (
        "-"
      );
    },
  },
  {
    field: "firstRedemptionImageFileId",
    headerName: "Redemption Image",
    flex: 1,
    filterable: false,
    renderCell: function render({ row }) {
      return <RedemptionImage fileId={row.firstRedemptionImageFileId} />;
    },
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: function render({ row }) {
      return (
        <Chip
          color={
            row.status === RedemptionStatus.REDEEMED
              ? "success"
              : row.status === RedemptionStatus.VALID
              ? "info"
              : "default"
          }
          label={row.status}
          variant="outlined"
        />
      );
    },
    hideable: false,
    flex: 1,
  },
  {
    field: " ",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: 8,
              marginBottom: 16,
            }}
          >
            <QRCode value={row.qrCodeUrl} size={64} />
          </div>
          <QrCodeDownloadMenuItem
            url={row.qrCodeUrl}
            filename={`redemption-${row.id}-code.png`}
          />
          {row.status === RedemptionStatus.VALID && (
            <MenuItem onClick={() => refreshRedemption(row.code)}>
              <Button
                startIcon={
                  refreshing ? (
                    <CircularProgress size={15} sx={{ marginRight: 1 }} />
                  ) : (
                    <CachedIcon />
                  )
                }
              >
                Refresh
              </Button>
            </MenuItem>
          )}
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
