import { DataGrid } from "@mui/x-data-grid";
import { useCan, useNavigation } from "@refinedev/core";
import { List, useDataGrid } from "@refinedev/mui";
import { branchCols } from "tables/branchCols";
import { Branch } from "types/branch";

export default function BranchList() {
  const { dataGridProps } = useDataGrid<Branch>();
  const { edit } = useNavigation();
  const { data: canEdit } = useCan({ resource: "branches", action: "edit" });

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={branchCols}
        autoHeight
        onRowClick={(params) => {
          canEdit?.can && edit("branches", params.row.id);
        }}
        columnVisibilityModel={{
          menu: canEdit?.can || false,
        }}
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </List>
  );
}
