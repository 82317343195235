import { Link, Tooltip, useTheme } from "@mui/material";
import { useConfig } from "provider/ConfigProvider";
import { useWeb3 } from "provider/Web3Provider";
import { useEffect, useState } from "react";
import { getToken } from "utils/tokenUtils";
import { publicKeyToUrl } from "./PublicKeyLink";

export default function TokenImageLink(props: {
  mintAddress: string;
  fallbackImageUrl?: string;
}) {
  const { mintAddress, fallbackImageUrl } = props;
  const config = useConfig();
  const theme = useTheme();
  const [tokenImageUrl, setTokenImageUrl] = useState<string>();
  const { metaplex } = useWeb3();

  useEffect(() => {
    getToken(metaplex, mintAddress)
      .then((sft) => {
        sft?.json?.image && setTokenImageUrl(sft.json.image);
      })
      .catch((e) => {
        console.error(
          "Failed to get token image: for mint address " + mintAddress,
          e
        );
        console.log("Falling back to image url: " + fallbackImageUrl);
        setTokenImageUrl(fallbackImageUrl);
      });
  }, [metaplex, mintAddress, fallbackImageUrl]);

  return (
    <>
      {tokenImageUrl && (
        <Link
          href={publicKeyToUrl(mintAddress, config.solanaCluster)}
          target="_blank"
          rel="noreferrer"
          display="flex"
        >
          <Tooltip title={mintAddress}>
            <img
              src={tokenImageUrl}
              alt="Unknown Token"
              style={{
                maxWidth: theme.spacing(4),
              }}
            />
          </Tooltip>
        </Link>
      )}
    </>
  );
}
