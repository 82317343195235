import { CircularProgress, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Connection,
  PublicKey,
  TokenAccountBalancePair,
  TokenAmount,
} from "@solana/web3.js";
import { useEffect, useState } from "react";
import { statCols } from "tables/statCols";
import { useConfig } from "../../provider/ConfigProvider";
import { CustomDivider } from "./CustomDivider";
import CurrencyAmount from "components/CurrencyAmount";

export default function K2TStatsPage() {
  const [tokenSupply, setTokenSupply] = useState<TokenAmount>();
  const [largestAccounts, setLargestAccounts] =
    useState<TokenAccountBalancePair[]>();
  const config = useConfig();

  useEffect(() => {
    const solanaConnection = new Connection(config.solanaRpcUrl);
    solanaConnection
      .getTokenSupply(new PublicKey(config.currencyMintAddress))
      .then((response) => {
        setTokenSupply(response.value);
      });
  }, [config.solanaRpcUrl, config.currencyMintAddress]);

  useEffect(() => {
    const solanaConnection = new Connection(config.solanaRpcUrl);
    solanaConnection
      .getTokenLargestAccounts(new PublicKey(config.currencyMintAddress))
      .then((response) => {
        setLargestAccounts(response.value);
      });
  }, [config.solanaRpcUrl, config.currencyMintAddress]);

  return (
    <Paper sx={{ p: 2 }}>
      {tokenSupply && largestAccounts ? (
        <>
          <Typography variant="h6">Total Supply</Typography>
          <CurrencyAmount uiAmount={tokenSupply.uiAmount} />
          <CustomDivider />
          <Typography variant="h6">Largest Accounts holding K2T</Typography>
          <TokenDistributionTable
            accounts={largestAccounts}
            supply={tokenSupply.uiAmount || 1}
          />
        </>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
}

export type TokenAccountBalancePairWithRank = TokenAccountBalancePair & {
  rank: number;
};

function TokenDistributionTable(props: {
  supply: number;
  accounts: TokenAccountBalancePair[];
}) {
  const richAccounts = props.accounts.map((account, index) => ({
    ...account,
    rank: index + 1,
  }));
  return (
    <div style={{ width: "100%" }}>
      <DataGrid<TokenAccountBalancePairWithRank>
        // sx={dataGridProps.sx}
        autoHeight
        rows={richAccounts}
        hideFooterPagination={true}
        columns={statCols(props.supply)}
        getRowId={(r) => {
          return r.address.toBase58();
        }}
      />
    </div>
  );
}
