import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, useTheme } from "@mui/material";

export const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const theme = useTheme();
  const urlParams = new URLSearchParams(window.location.search);
  const toParam = urlParams.get("to");

  return (
    <Box>
      <Grid
        item
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        margin="auto"
        height="100vh"
      >
        <img
          src="/logo.png"
          alt="Refine"
          style={{ width: "200px", marginBottom: theme.spacing(4) }}
        />
        <Button
          size="large"
          onClick={() =>
            loginWithRedirect({
              appState: {
                returnTo: toParam ? decodeURIComponent(toParam) : "/",
              },
            })
          }
          variant="outlined"
        >
          Sign in
        </Button>
      </Grid>
    </Box>
  );
};
