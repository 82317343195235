import { Grid } from "@mui/material";
import { useParsed, useUpdate } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import React from "react";
import { Airdrop } from "types/airdrop";
import CreateAirdropDialog from "./CreateAirdropDialog";
import { DataGrid } from "@mui/x-data-grid";
import { airdropCols } from "tables/airdropCols";

export default function AirdropList() {
  const { id: couponId } = useParsed();
  const { dataGridProps } = useDataGrid<Airdrop>({
    resource: "airdrops/" + couponId,
    queryOptions: { refetchInterval: 3000, refetchIntervalInBackground: false },
  });

  const { mutate } = useUpdate();

  const retryAirdrop = (airdropId: string) => {
    mutate({ resource: "airdrops", id: airdropId, values: {} });
  };

  return (
    <Grid container direction={"column"} gap={3}>
      <Grid item justifyContent={"flex-end"} display={"flex"}>
        <CreateAirdropDialog />
      </Grid>
      <Grid item>
        <DataGrid
          {...dataGridProps}
          columns={airdropCols(retryAirdrop)}
          autoHeight
          sx={{
            ...dataGridProps.sx,
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
