import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useParsed } from "@refinedev/core";
import { CreateButton, SaveButton } from "@refinedev/mui";
import { useModalForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { CreateAirdrop, defaultCreateAidrop } from "types/airdrop";

export default function CreateAirdropDialog() {
  const { id: couponId } = useParsed();

  const methods = useModalForm<CreateAirdrop>({
    refineCoreProps: {
      action: "create",
      resource: "airdrops",
    },
    defaultValues: defaultCreateAidrop,
  });

  const {
    control,
    handleSubmit,
    modal,
    reset,
    refineCore: { onFinish },
  } = methods;

  const handleClose = () => {
    reset();
    modal.close();
  };

  const [pending, setPending] = useState(false);

  const handleSave = async (values: FieldValues) => {
    setPending(true);
    await onFinish({
      ...values,
      couponId: couponId,
    });
    setPending(false);
    handleClose();
  };

  return (
    <>
      <CreateButton onClick={() => modal.show()}>Create Airdrop</CreateButton>
      <Dialog open={modal.visible} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogTitle>Create Airdrop</DialogTitle>
          <DialogContent>
            <Grid container flexDirection={"column"}>
              <Grid item>
                <FormTextField
                  required
                  control={control}
                  name={"receiverWalletKey"}
                  label="Receiver Wallet Key"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormTextField
                  control={control}
                  name={"amount"}
                  label="Coupon Amount"
                  type="number"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={pending}>
              Cancel
            </Button>
            <SaveButton variant="contained" type="submit" loading={pending} />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
