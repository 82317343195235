import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useBranchContext } from "provider/BranchProvider";

export default function BranchSelect() {
  const { branches, branch, setBranch } = useBranchContext();

  const handleChange = (event: SelectChangeEvent) => {
    const newBranch = branches.find(
      (b) => b.id.toString() === event.target.value.toString()
    );
    setBranch(newBranch);
  };

  return (
    <FormControl fullWidth>
      <InputLabel variant="standard">Branch</InputLabel>
      <Select
        size="small"
        label="Branch"
        variant="standard"
        value={branch?.id.toString() || ""}
        onChange={handleChange}
        sx={{ maxWidth: "550px" }}
      >
        {branches.length > 1 && (
          <MenuItem value={""} sx={{ borderBottom: "1px solid lightgrey" }}>
            All Branches
          </MenuItem>
        )}
        {branches.map((branch) => (
          <MenuItem key={branch.id} value={branch.id}>
            {branch.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
