import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box, FormHelperText, IconButton, Tooltip } from "@mui/material";
import { ImageUpload } from "components/layout/ImageUpload";
import { CSSProperties, useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Event, Image, ofEvent } from "types/event";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  imageBoxStyles,
  imageButtonStyles,
  rejectStyle,
} from "utils/imageUtils";

export function ImageSection() {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<Event>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: ofEvent("images"),
    keyName: "key",
  });
  const [uploads, setUploads] = useState<File[]>([]);
  const imageError = errors[ofEvent("images")];

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploads((u) => [...u, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      multiple: true,
      maxSize: 5 * 1024 * 1024,
      onDrop,
      maxFiles: 4,
      onDropRejected: (fileRejections: FileRejection[]) => {
        if (
          fileRejections.find((fr) => fr.errors[0].code === "file-too-large")
        ) {
          setError(ofEvent("images"), {
            message: "Images must be smaller than 5MB",
          });
        }
      },
      onDropAccepted: () => clearErrors(ofEvent("images")),
    });

  const style: CSSProperties = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
      }}
    >
      {fields.map((image: Image, index: number) => (
        <Box key={image.id} sx={imageBoxStyles}>
          <Box position={"absolute"} top={-7} right={-7}>
            <Tooltip
              title={
                image.highlight ? "Highlight image" : "Set as highligh image"
              }
            >
              <IconButton
                size="small"
                sx={{ ...imageButtonStyles, marginRight: "2px" }}
                onClick={() => {
                  fields.map((field, i: number) =>
                    update(i, { ...field, highlight: false })
                  );
                  update(index, { ...image, highlight: true });
                }}
              >
                {image.highlight ? <StarIcon /> : <StarBorderIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={"Remove image"}>
              <IconButton
                size="small"
                sx={imageButtonStyles}
                onClick={() => {
                  // Set first image to highlight if removed image was
                  if (image.highlight && fields.length >= 2) {
                    update(0, { ...fields[0], highlight: true });
                  }
                  remove(index);
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
            src={`${image.url}?size=small`}
            alt="Event Banner"
          />
        </Box>
      ))}
      {uploads.map((u, i) => (
        <Box key={i} sx={imageBoxStyles}>
          <ImageUpload
            key={i}
            fileToUpload={u}
            onUploadComplete={(uploadedFile) => {
              setUploads((u) => u.filter((_, index) => index !== i));
              append({
                id: uploadedFile.id,
                url: uploadedFile.url,
                highlight: fields.length === 0 ? true : false,
              });
            }}
          />
        </Box>
      ))}
      {fields.length + uploads.length < 4 && (
        <Box>
          <Box sx={imageBoxStyles} {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <AddIcon fontSize="large" />
          </Box>
          <FormHelperText error>{imageError?.message}</FormHelperText>
        </Box>
      )}
    </Box>
  );
}
