import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";

export default function FormSelectField<LIST_TYPE>(
  props: {
    name: string;
    label: string;
    list: LIST_TYPE[];
    getListLabel?: (entry: LIST_TYPE) => string;
    getListKey?: (entry: LIST_TYPE) => string | number | boolean;
    noSelectionText?: string;
    allowEmpty?: boolean;
    control: Control<any, any>;
    size?: "small";
  } & Partial<
    Omit<SelectProps, "onChange" | "onBlur" | "value" | "name" | "error">
  >
) {
  const {
    name,
    label,
    list,
    getListLabel,
    getListKey,
    noSelectionText,
    control,
    allowEmpty,
    ...selectAndFormControlProps
  } = props;
  const { variant, ...rest } = selectAndFormControlProps;
  const EMPTY_VALUE = "";

  function getListKeyOrDefault(value: any) {
    if (value === EMPTY_VALUE) {
      return value;
    }
    if (!list || list.length === 0) {
      return "";
    }
    let result: string | number | boolean = "";
    if (value !== null && value !== undefined) {
      if (getListKey) {
        result = getListKey(value);
      } else {
        result = value.toString();
      }
    }
    if (result === null || result === undefined) {
      result = "";
    }
    return result;
  }

  function getListLabelOrDefault(value: LIST_TYPE) {
    if (
      value === undefined ||
      value === null ||
      value === "" ||
      list.length === 0
    ) {
      return "---";
    }
    if (!getListLabel) {
      return (value as any).toString();
    }
    return getListLabel(value);
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: props.required || false,
          message: "This is required",
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          error={!!error}
          required={props.required || false}
          variant="standard"
          size={props.size}
          margin="normal"
          sx={{ minWidth: 180 }}
        >
          <InputLabel id={"select_" + name + "_label"} shrink={allowEmpty}>
            {label}
          </InputLabel>
          <Select
            id={"select_" + name}
            labelId={"select_" + name + "_label"}
            label={label}
            name={field.name}
            variant="standard"
            displayEmpty={allowEmpty}
            onChange={(event) => {
              const e =
                event as unknown as React.ChangeEvent<HTMLSelectElement>;
              if (e.target.value === EMPTY_VALUE) {
                field.onChange("");
              } else {
                const value = list.find(
                  (entry: LIST_TYPE) =>
                    getListKeyOrDefault(entry) === e.target.value
                );
                if (value !== null && value !== undefined) {
                  field.onChange(value);
                }
              }
            }}
            onBlur={field.onBlur}
            value={getListKeyOrDefault(field.value)}
            error={!!error}
            {...rest}
          >
            {allowEmpty && (
              <MenuItem value={EMPTY_VALUE} key={EMPTY_VALUE}>
                {noSelectionText || "---"}
              </MenuItem>
            )}
            {list.map((item) => (
              <MenuItem
                id={"option_" + getListKeyOrDefault(item)}
                value={getListKeyOrDefault(item)}
                key={getListKeyOrDefault(item)}
              >
                {getListLabelOrDefault(item)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
