import { AxiosInstance } from "axios";
import { Membership } from "types/membership";
import { queryList } from "utils/rest/restApi";

export const getMemberships = (
  axios: AxiosInstance,
  branchId?: number
): Promise<Membership[]> => {
  return queryList<Membership>(
    axios,
    "memberships/all",
    branchId ? `branchId=${branchId}` : ""
  );
};
