export interface Ticket {
  id: string;
  mintAddress: string;
  name: string;
  description: string;
  image: { id: string; url: string } | null;
  supply: number | null;
  priceInCents: number | null;
  salesStart: string | null;
}

export const defaultEventTicket: Ticket = {
  id: "",
  mintAddress: "",
  name: "",
  description: "",
  image: null,
  supply: null,
  priceInCents: 0,
  salesStart: "",
};
