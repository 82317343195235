import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance } from "axios";
import LoadingScreen from "components/layout/LoadingScreen";
import { createContext, ReactNode, useContext } from "react";

export const AxiosContext = createContext<AxiosInstance>({} as any);

export function useAxios(): AxiosInstance {
  return useContext(AxiosContext);
}

export function AxiosProvider(props: { children: ReactNode }) {
  const axiosInstance = axios.create();
  const { isLoading, getIdTokenClaims } = useAuth0();

  if (isLoading) {
    return <LoadingScreen loading />;
  }

  getIdTokenClaims().then((token) => {
    if (token) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token.__raw}`,
      };
    }
  });

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {props.children}
    </AxiosContext.Provider>
  );
}
