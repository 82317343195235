import { CircularProgress } from "@mui/material";
import { ReactNode } from "react";

export default function LoadingScreen(props: {
  loading: boolean;
  children?: ReactNode;
}) {
  const { loading, children } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
      }}
    >
      {loading ? <CircularProgress /> : children}
    </div>
  );
}
