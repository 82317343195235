import { Checkbox } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import EditMenuItem from "components/table/EditMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import { Branch, ofBranch } from "types/branch";

export const branchCols: GridColDef<Branch>[] = [
  {
    field: ofBranch("id"),
    headerName: "Id",
    flex: 1,
  },
  {
    field: ofBranch("name"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofBranch("abbreviation"),
    headerName: "Abbreviation",
    flex: 1,
  },
  {
    field: ofBranch("contact"),
    headerName: "Contact",
    flex: 1,
  },
  {
    field: ofBranch("showOnEventPage"),
    headerName: "Show on event page",
    renderCell: function render({ row }) {
      return <Checkbox checked={row.showOnEventPage} disabled />;
    },
    flex: 1,
  },
  {
    field: "menu",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <EditMenuItem resource={"branches"} id={row.id} />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
