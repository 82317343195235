import UnexpectedError from "pages/UnexpectedError";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Config } from "types/config";
import { getConfig } from "utils/api/ConfigApi";
import { useAxios } from "./AxiosProvider";
import NoPermissionsError from "pages/NoPermissionsError";
import { useAccount } from "./AccountProvider";

export const ConfigContext = createContext<Config>({} as any);

export const ConfigProvider = (props: { children: ReactNode }) => {
  const [config, setConfig] = useState<Config>();
  const axios = useAxios();
  const [loading, setLoading] = useState(true);

  const account = useAccount();

  useEffect(() => {
    getConfig(axios)
      .then(setConfig)
      .catch(() => console.log("Unexpected Error"))
      .finally(() => setLoading(false));
  }, [axios]);

  if (!config) {
    if (!account) return <></>;
    else if (account.roles.length < 1 || account.roles[0] === "")
      return <NoPermissionsError user={account.email} />;
    else return <UnexpectedError loading={loading} />;
  }

  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export function useConfig(): Config {
  return useContext(ConfigContext);
}
