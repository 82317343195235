import { Metaplex } from "@metaplex-foundation/js";
import { Connection } from "@solana/web3.js";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useConfig } from "./ConfigProvider";
import { getToken } from "utils/tokenUtils";

export interface Web3Utils {
  metaplex: Metaplex;
  currencyImageUrl: string;
}

export const Web3Context = createContext<Web3Utils>({} as any);

export const Web3Provider = (props: { children: ReactNode }) => {
  const config = useConfig();
  const metaplex = Metaplex.make(new Connection(config.solanaRpcUrl));
  const [currencyImageUrl, setCurrencyImageUrl] = useState<string>();

  useEffect(() => {
    getToken(metaplex, config.currencyMintAddress)?.then((token) =>
      setCurrencyImageUrl(token.json?.image)
    );
  }, [config.currencyMintAddress, metaplex]);

  if (!currencyImageUrl) return <></>;

  return (
    <Web3Context.Provider
      value={{ metaplex, currencyImageUrl: currencyImageUrl }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};

export function useWeb3(): Web3Utils {
  return useContext(Web3Context);
}
