import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, FormHelperText, IconButton, Tooltip } from "@mui/material";
import ImageCropDialog from "components/ImageCropDialog";
import { ImageUpload } from "components/layout/ImageUpload";
import { CSSProperties, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Control, Controller, useFormContext } from "react-hook-form";
import "react-image-crop/dist/ReactCrop.css";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  imageBoxStyles,
  imageButtonStyles,
  rejectStyle,
} from "utils/imageUtils";

export default function FormImageField(props: {
  control: Control;
  name: string;
  required?: boolean;
  squareImages?: boolean;
}) {
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const [upload, setUpload] = useState<File>();
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [cropFile, setCropFile] = useState<File>();

  const handleCropComplete = (file?: File) => {
    file && setUpload(file);
    setCropDialogOpen(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (props.squareImages) {
      setCropFile(acceptedFiles[0]);
      setCropDialogOpen(true);
    } else {
      setUpload(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      maxSize: 5 * 1024 * 1024,
      onDrop,
      maxFiles: 1,
      onDropRejected: (fileRejections: FileRejection[]) => {
        setError(props.name, {
          message: fileRejections[0].errors[0].message,
        });
      },
      onDropAccepted: () => clearErrors(props.name),
    });

  const style: CSSProperties = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        required: {
          value: props.required || false,
          message: "This is required",
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return value ? (
          <Box sx={imageBoxStyles}>
            <Box position={"absolute"} top={-7} right={-7}>
              <Tooltip title={"Remove image"}>
                <IconButton
                  size="small"
                  sx={imageButtonStyles}
                  onClick={() => onChange(undefined)}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
              src={`${value.url}?size=small`}
              alt="Event Banner"
            />
          </Box>
        ) : upload ? (
          <Box sx={imageBoxStyles}>
            <ImageUpload
              fileToUpload={upload}
              onUploadComplete={(uploadedFile) => {
                setUpload(undefined);
                onChange({
                  id: uploadedFile.id,
                  url: uploadedFile.url,
                });
              }}
            />
          </Box>
        ) : (
          <Box>
            <Box sx={imageBoxStyles} {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <AddIcon fontSize="large" />
            </Box>
            <ImageCropDialog
              open={cropDialogOpen}
              handleCropComplete={handleCropComplete}
              file={cropFile}
            />
            <FormHelperText error>
              {errors[props.name]?.message?.toString() || error?.message}
            </FormHelperText>
          </Box>
        );
      }}
    />
  );
}
