import { Button } from "@mui/material";
import { SaveButton } from "@refinedev/mui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { isEventPublished, isEventScheduled } from "utils/eventUtils";

export default function EventButtons(props: {
  handleSave: (e: React.BaseSyntheticEvent, draft: boolean) => void;
}) {
  const { handleSave } = props;
  const { watch } = useFormContext();
  const publishAt = watch("publishAt");
  const draft = watch("draft");

  return (
    <>
      <Button
        variant="outlined"
        onClick={(e: React.BaseSyntheticEvent) => handleSave(e, true)}
      >
        {draft
          ? "Save as draft"
          : isEventPublished(publishAt)
          ? "Unpublish"
          : isEventScheduled(publishAt) && "Unschedule"}
      </Button>
      <SaveButton
        onClick={(e: React.BaseSyntheticEvent) => handleSave(e, false)}
      >
        {draft
          ? isEventScheduled(publishAt)
            ? "Schedule"
            : "Publish"
          : "Save"}
      </SaveButton>
    </>
  );
}
