import { AccessControlProvider } from "@refinedev/core";
import { AxiosInstance } from "axios";
import { MemoryAdapter, newEnforcer, newModel } from "casbin.js";
import { isBranchManager, UserRole } from "types/user";
import { getAccount } from "utils/api/AccountApi";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new MemoryAdapter(`
p, ${UserRole.ADMIN}, *
p, ${UserRole.HEADQUARTER}, *

p, ${UserRole.BRANCH_MANAGER}, memberships, (list)|(create)
p, ${UserRole.BRANCH_MANAGER}, mint-requests, list
p, ${UserRole.BRANCH_MANAGER}, redemptions, list
p, ${UserRole.BRANCH_MANAGER}, events, (list)|(create)
p, ${UserRole.BRANCH_MANAGER}, events/*, (edit)|(show)
p, ${UserRole.BRANCH_MANAGER}, admin
p, ${UserRole.BRANCH_MANAGER}, branches, list
p, ${UserRole.BRANCH_MANAGER}, memberships, list
p, ${UserRole.BRANCH_MANAGER}, coupons, (list)|(create)|(edit)|(show)
`);

let rolesPromise: Promise<UserRole[]> | null = null;

export const createAccessControlProvider = (
  axios: AxiosInstance
): AccessControlProvider => ({
  can: async ({ resource, action, params }) => {
    if (rolesPromise === null) {
      rolesPromise = getAccount(axios).then((account) =>
        account.roles.map((role) =>
          isBranchManager(role) ? UserRole.BRANCH_MANAGER : (role as UserRole)
        )
      );
    }
    const roles = await rolesPromise;
    const enforcer = await newEnforcer(model, adapter);

    let obj = resource;

    if (action === "delete" || action === "edit" || action === "show") {
      obj = `${resource}/${params?.id}`;
    }

    if (action === "field") {
      obj = `${resource}/${params?.field}`;
    }

    // If one of the user's role has enough permissions, allow it
    const cans = await Promise.all(
      roles!!.map((role) => enforcer.enforce(role, obj, action))
    );
    return Promise.resolve({ can: cans.includes(true) });
  },
});
