import { Box, FormControlLabel, Checkbox } from "@mui/material";
import FormDateField from "components/form/FormDateField";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export const PublishAtFields = () => {
  const { control, watch, setValue } = useFormContext();
  const [scheduled, setScheduled] = useState(false);
  const publishAt = watch("publishAt");

  useEffect(() => {
    if (publishAt) {
      setScheduled(true);
    } else {
      setScheduled(false);
    }
  }, [publishAt]);

  return (
    <Box display="flex" flexDirection="row" gap={4} alignItems={"end"}>
      <FormControlLabel
        control={
          <Checkbox
            checked={scheduled}
            onChange={(_, checked) => {
              setScheduled(checked);
              !checked && setValue("publishAt", "");
            }}
          />
        }
        label={"Publish Scheduled"}
      />
      <FormDateField
        name="publishAt"
        control={control}
        label={"Publish At"}
        disabled={!scheduled}
        required={scheduled}
      />
    </Box>
  );
};
