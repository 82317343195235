import { Sft } from "@metaplex-foundation/js";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { PublicKey } from "@solana/web3.js";
import { publicKeyToUrl } from "components/PublicKeyLink";
import { useConfig } from "provider/ConfigProvider";
import { useWeb3 } from "provider/Web3Provider";
import { useEffect, useState } from "react";
import { Transfer } from "types/transaction";

export const TokenCard = (props: { transfer: Transfer }) => {
  const { transfer } = props;
  const { solanaCluster } = useConfig();
  const [token, setToken] = useState<Sft>();
  const theme = useTheme();
  const { metaplex } = useWeb3();

  useEffect(() => {
    metaplex
      .nfts()
      .findByMint({ mintAddress: new PublicKey(transfer.mintAddress) })
      .then((sft) => {
        setToken(sft as Sft);
      });
  }, [metaplex, transfer]);

  if (!token) return <></>;

  return (
    <Card sx={{ position: "relative" }}>
      <CardMedia
        component="img"
        image={token.json?.image}
        alt={token.json?.name}
      />
      <Chip
        label={`${transfer.amount * -1} x`}
        variant="filled"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(1),
          background: "black",
          color: "white",
        }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          align="center"
          noWrap
          overflow={"hidden"}
        >
          {token.json?.name}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Link
          href={publicKeyToUrl(token.address.toBase58(), solanaCluster)}
          sx={{ textDecoration: "none" }}
          target="_blank"
          rel="noreferrer"
          display="flex"
        >
          <Button size="small">Review Token</Button>
        </Link>
      </CardActions>
    </Card>
  );
};
