import { AxiosInstance } from "axios";
import { EntityRef } from "./entities";
import { handleErrorResponse, handleSuccessResponse } from "./errorHandling";
import { Page, Pageable } from "./pageable";

export const apiHost = "/api";

export type CrudApi<T> = {
  queryPage: (axios: AxiosInstance, pageable: Pageable) => Promise<Page<T>>;
  queryList: (axios: AxiosInstance) => Promise<T[]>;
  queryOne: (axios: AxiosInstance, id: string | number) => Promise<T>;
  createOne: (axios: AxiosInstance, entity: T) => Promise<T>;
  updateOne: (axios: AxiosInstance, entity: T) => Promise<T>;
};

export function queryPage<DTO>(
  customAxios: AxiosInstance,
  relativeUri: string,
  pageable: Pageable,
  params?: string
): Promise<Page<DTO>> {
  const baseUri = apiHost + "/" + relativeUri;
  return customAxios
    .get(`${baseUri}?${toUrlParams(pageable)}` + (params ? "&" + params : ""))
    .then(handleSuccessResponse, handleErrorResponse);
}

export function queryList<DTO>(
  customAxios: AxiosInstance,
  relativeUri: string,
  params?: string
): Promise<DTO[]> {
  const baseUri = apiHost + "/" + relativeUri;
  return customAxios
    .get(`${baseUri}?` + (params ? params : ""))
    .then(handleSuccessResponse, handleErrorResponse);
}

export function queryOne<DTO>(
  customAxios: AxiosInstance,
  relativeUri: string,
  id?: string | number,
  params?: string
): Promise<DTO> {
  const baseUri = apiHost + "/" + relativeUri;
  const url = id ? `${baseUri}/${id}` : baseUri;
  return customAxios
    .get(url + (params ? "?" + params : ""))
    .then(handleSuccessResponse, handleErrorResponse);
}

export function queryFile(
  customAxios: AxiosInstance,
  relativeUri: string,
  id?: string | number,
  params?: string,
  mediaType?: string
): Promise<File> {
  const baseUri = relativeUri.startsWith("http")
    ? relativeUri
    : apiHost + "/" + relativeUri;
  const headers: any = {};
  if (mediaType) {
    headers.accept = mediaType;
  }
  const url = id ? `${baseUri}/${id}` : baseUri;
  return customAxios
    .request({
      url: url + (params ? "?" + params : ""),
      method: "GET",
      responseType: "blob",
      headers: headers,
    })
    .then(
      (response) => {
        let fileName = "file";
        if (
          response.headers["content-disposition"] &&
          response.headers["content-disposition"].includes("filename=")
        ) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "");
        }
        return Promise.resolve(new File([response.data as Blob], fileName));
      },
      (error) => {
        throw new Error(error.message);
      }
    );
}

export function createOne<DTO>(
  customAxios: AxiosInstance,
  relativeUri: string,
  entity: DTO
): Promise<DTO> {
  const baseUri = apiHost + "/" + relativeUri;
  return customAxios
    .post(baseUri, entity)
    .then(handleSuccessResponse, handleErrorResponse);
}

export function createOneWithSeparateDto<CREATE_DTO, DTO>(
  customAxios: AxiosInstance,
  relativeUri: string,
  entity: CREATE_DTO
): Promise<DTO> {
  const baseUri = apiHost + "/" + relativeUri;
  return customAxios
    .post(baseUri, entity)
    .then(handleSuccessResponse, handleErrorResponse);
}

export function updateOne<DTO extends EntityRef>(
  customAxios: AxiosInstance,
  relativeUri: string,
  entity: DTO
): Promise<DTO> {
  const baseUri = apiHost + "/" + relativeUri;
  return customAxios
    .put(`${baseUri}/${entity.id}`, entity)
    .then(handleSuccessResponse, handleErrorResponse);
}

export function createOneTag(
  customAxios: AxiosInstance,
  relativeUri: string,
  entity: string
) {
  const baseUri = apiHost + "/" + relativeUri;
  return customAxios.post(`${baseUri}`, entity);
}

export function deleteOne(
  customAxios: AxiosInstance,
  relativeUri: string,
  id: string | number
): Promise<void> {
  const baseUri = apiHost + "/" + relativeUri;
  return customAxios
    .delete(`${baseUri}/${id}`)
    .then(handleSuccessResponse, handleErrorResponse);
}

function toUrlParams(pageable: Pageable) {
  const parts: string[] = [];
  function pushIfDefined<T>(v: T | undefined, f: (v: T) => string) {
    if (v !== undefined) parts.push(f(v));
  }
  pushIfDefined(pageable.page, (v) => `page=${v}`);
  pushIfDefined(pageable.size, (v) => `size=${v}`);
  pushIfDefined(pageable.search, (v) => `search=${v}`);
  pushIfDefined(pageable.search, (v) => `search=${v}`);
  pushIfDefined(pageable.sort, (v) => `sort=${v.field},${v.order}`);
  pushIfDefined(pageable.filter, (v) =>
    v.map((f) => `${f.field}=${f.value}`).join("&")
  );
  return parts.join("&");
}

export function createParam(name: string, value: string) {
  return name + "=" + value;
}
