import { Grid } from "@mui/material";
import FormEditorField from "components/form/FormEditorField";
import FormTextField from "components/form/FormTextField";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ExternalLinkSection } from "../ExternalLinkSection";
import { MediaSection } from "../MediaSection";

export default function DetailPage() {
  const { control } = useFormContext();

  return (
    <Grid container flexDirection={"column"} spacing={6}>
      <Grid item display="flex" flexDirection={"column"}>
        <FormTextField
          name="summary"
          control={control}
          label={"Summary"}
          helperText={"Short description about your event (140 characters max)"}
          additionalRules={{
            maxLength: 140,
          }}
        />
        <FormEditorField
          name="description"
          control={control}
          label="Description"
        />
      </Grid>
      <Grid item>
        <ExternalLinkSection />
      </Grid>
      <Grid item>
        <MediaSection />
      </Grid>
    </Grid>
  );
}
