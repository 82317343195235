export function displayUSD(value: number | null): string {
  return `${
    value?.toLocaleString(undefined, {
      maximumFractionDigits: 2
    }) || "?"
  } $`;
}


// row.priceInUsdCents ? row.priceInUsdCents / 100 + " $" : "-";
