import { propertiesOf } from "utils/propertyOf";
import { EntityRef } from "utils/rest/entities";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.BranchDto] */
export interface Branch extends EntityRef {
  id: number;
  name: string;
  abbreviation: string;
  contact: string | null;
  showOnEventPage: boolean;
}

export const ofBranch = propertiesOf<Branch>();

export const defaultBranch: Branch = {
  id: 0,
  name: "",
  abbreviation: "",
  contact: "",
  showOnEventPage: false,
};
