import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Chip, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { MembershipCell } from "components/table/MembershipCell";
import { Link } from "react-router-dom";
import { Checkout, ofCheckout } from "types/checkout";
import { RedemptionStatus, ofRedemption } from "types/redemption";

export const checkoutCols: GridColDef<Checkout>[] = [
  {
    field: ofCheckout("id"),
    headerName: "ID",
    type: "number",
    filterable: false,
  },
  {
    field: ofRedemption("creationId"),
    headerName: "Redemption Creation ID",
    flex: 1,
    sortable: false,
    renderCell: function render({ row }) {
      return row.redemption.creationId;
    },
  },
  {
    field: "membership",
    headerName: "Membership",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <MembershipCell
          membership={row.redemption.membership}
          mintAddress={row.redemption.membership.membershipKey}
        />
      );
    },
  },
  {
    field: ofCheckout("checkoutUrl"),
    headerName: "Details",
    flex: 1,
    sortable: false,
    renderCell: function render({ row }) {
      return (
        <Link
          to={row.checkoutUrl}
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyItems="start"
            gap={1}
          >
            <Typography>Stripe Checkout</Typography>
            <OpenInNewIcon
              sx={{ color: (theme) => theme.palette.text.primary }}
            />
          </Stack>
        </Link>
      );
    },
  },
  {
    field: ofCheckout("boughtAt"),
    headerName: "Bought at",
    flex: 1,
    hideable: false,
    renderCell: function render({ row }) {
      return <span>{new Date(row.boughtAt).toLocaleString()}</span>;
    },
  },
  {
    field: ofRedemption("status"),
    headerName: "Redemption Status",
    flex: 1,
    sortable: false,
    renderCell: function render({ row }) {
      return (
        <Chip
          color={
            row.redemption.status === RedemptionStatus.REDEEMED
              ? "success"
              : row.redemption.status === RedemptionStatus.VALID
              ? "info"
              : "default"
          }
          label={row.redemption.status}
          variant="outlined"
        />
      );
    },
  },
];
