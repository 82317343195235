import { propertiesOf } from "utils/propertyOf";
import { Redemption } from "./redemption";

export interface Checkout {
  id: number;
  redemption: Redemption;
  checkoutUrl: string;
  boughtAt: Date;
}

export const ofCheckout = propertiesOf<Checkout>();
