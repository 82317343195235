/**
 * Mirrors Spring's Page object [org.springframework.data.domain.Page].
 */
export interface Page<T> {
  content: T[];
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
  last: boolean;
}

/**
 * Mirrors Spring's Pageable object [org.springframework.data.domain.Pageable].
 */
export interface Pageable {
  /** page number, 0 based */
  page?: number;
  /** size of page */
  size?: number;
  sort?: Sort;
  search?: string;
  filter?: { field: string; value: string }[];
}

export interface Sort {
  field: string;
  order: "asc" | "desc";
}

export function emptyPage(): Page<never> {
  return {
    number: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0,
    content: [],
    last: true,
  };
}

export function defaultPageable(size?: number): Pageable {
  return {
    page: 0,
    size: size || 10,
  };
}

export function singlePageable(): Pageable {
  return {
    page: 0,
    size: 1,
  };
}

export function fullPageable(): Pageable {
  return {
    page: 0,
    size: 10000,
  };
}

/**
 * Checks if the given data is a Page object.
 */
export function isPage<T>(data: unknown): data is Page<T> {
  return (
    typeof data === "object" &&
    data !== null &&
    "content" in data &&
    "totalElements" in data
  );
}
