import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useList, useShow } from "@refinedev/core";
import { Controller, useFormContext } from "react-hook-form";
import { ofEvent, Event } from "types/event";
import { Membership, isLevelMembership } from "types/membership";

export function MembershipSelect() {
  const event = useShow<Event>();
  const { control, watch } = useFormContext();
  const allowedMembershipsWatch = watch(ofEvent("allowedMemberships"));
  const { data } = useList<Membership>({ resource: "memberships/all" });

  const memberships = data?.data.filter((m) => !isLevelMembership(m));

  if (!memberships) return <></>;

  // Sort memberships by branchId, with the memberships of current branch first
  memberships.sort((a, b) => {
    if (event.queryResult.data?.data.branchId === a.branchId) {
      return -1;
    } else {
      if (event.queryResult.data?.data.branchId === b.branchId) {
        return 1;
      } else {
        return 0;
      }
    }
  });
  const noMemberships = memberships.length === 0;

  return (
    <Controller
      control={control}
      name={ofEvent("allowedMemberships")}
      defaultValue={allowedMembershipsWatch}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          sx={{ minWidth: 300 }}
          value={field.value || []}
          multiple
          disabled={noMemberships}
          options={memberships}
          getOptionLabel={(m: Membership) => m.name}
          onChange={(e, value) => field.onChange(value)}
          isOptionEqualToValue={(option, m) => option.id === m.id}
          renderOption={(props, m, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
              {m.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              label={"Allowed Partner Memberships"}
              error={!!error}
              helperText={
                error?.message ||
                (noMemberships ? "No memberships available" : null)
              }
              margin="normal"
              disabled={noMemberships}
            />
          )}
        />
      )}
    />
  );
}
