import { Membership } from "./membership";
import { Redemption } from "./redemption";
import { propertiesOf } from "../utils/propertyOf";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.MintRequestDto] */
export interface MintRequest {
  id: number;
  walletKey: string;
  membership: Membership;
  redemption: Redemption | null;
  amountOfCents: number;
  serial: number;
  createdAt: Date;
  createdBy: string;
  status: MintRequestStatus;
  mintAddress: string;
  sentryId: string | null;
}

export enum MintRequestStatus {
  PENDING = "PENDING",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
}

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.CreateMintRequestDto] */
export interface ICreateMintRequest {
  walletKey: string;
  membershipId?: number;
  amountOfCents?: number;
  serial?: number;
}

export const ofCreateMintRequest = propertiesOf<ICreateMintRequest>();

export const defaultMintRequest: ICreateMintRequest = {
  walletKey: "",
  membershipId: undefined,
  amountOfCents: undefined,
  serial: undefined,
};

export const ofMintRequest = propertiesOf<MintRequest>();
