import { Button, MenuItem } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PublicKeyLink } from "components/PublicKeyLink";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import TransactionStatusChip from "components/table/TransactionStatusChip";
import { Airdrop } from "types/airdrop";
import { TransactionStatus } from "types/transaction";
import CachedIcon from "@mui/icons-material/Cached";

export const airdropCols = (
  retryAirdrop: (airdropId: string) => void
): GridColDef<Airdrop>[] => [
  {
    field: "id",
    headerName: "Id",
  },
  {
    field: "walletKey",
    headerName: "Wallet",
    flex: 1,
    renderCell: function render({ row }) {
      return <PublicKeyLink pubKey={row.walletKey} type="address" />;
    },
  },
  {
    field: "transactionSignature",
    headerName: "Signature",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <PublicKeyLink pubKey={row.transactionSignature} type="transaction" />
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
    renderCell: function render({ row }) {
      return <span>{new Date(row.createdAt).toLocaleString()}</span>;
    },
  },
  {
    field: "resolvedAt",
    headerName: "Resolved at",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <span>
          {row.resolvedAt ? new Date(row.resolvedAt).toLocaleString() : "-"}
        </span>
      );
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    type: "number",
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: function render({ row }) {
      return <TransactionStatusChip status={row.status} />;
    },
  },
  {
    field: "more",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <MenuItem
            onClick={() => retryAirdrop(row.id)}
            disabled={row.status === TransactionStatus.SUCCEEDED}
          >
            <Button startIcon={<CachedIcon />}>Retry</Button>
          </MenuItem>
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
