import { CSSProperties } from "react";

export const baseStyle: CSSProperties = {
  maxWidth: "100%",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 5,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const focusedStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};

export const imageBoxStyles: CSSProperties = {
  width: "200px",
  height: "200px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #aaa",
  borderRadius: "5px",
  padding: "2px",
  position: "relative",
  cursor: "pointer",
};

export const imageButtonStyles: CSSProperties = {
  backgroundColor: "rgba(255,255,255) !important",
  border: "1px solid grey",
};
