import { Tooltip, IconButton, Box } from "@mui/material";
import React, { ReactNode, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";

export default function ShareColumn(props: {
  value?: string;
  emailText: string;
  url?: string;
  content?: ReactNode;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("Copied to clipboard");

  const handleTooltip = (text: string) => {
    setTooltipTitle(text);
    setShowTooltip(true);
  };

  return (
    <Box display="flex" gap={1} alignItems="center">
      {props.content || <span>{props.value}</span>}
      <Tooltip
        title={tooltipTitle}
        open={showTooltip}
        leaveDelay={1000}
        onClose={() => setShowTooltip(false)}
      >
        <div>
          <IconButton
            size="small"
            onClick={async (event) => {
              event.stopPropagation();
              await navigator.clipboard.writeText(
                props.value || props.url || ""
              );
              handleTooltip("Copied to clipboard");
            }}
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
          <ShareIconButton openTooltip={handleTooltip} {...props} />
        </div>
      </Tooltip>
    </Box>
  );
}

const ShareIconButton = (props: {
  value?: string;
  emailText: string;
  url?: string;
  openTooltip: (value: string) => void;
}) => {
  return (
    <IconButton
      size="small"
      onClick={async (event) => {
        event.stopPropagation();
        try {
          navigator
            .share({
              title: props.value || props.url || "",
              text: `${props.emailText}${props.url ? `\n${props.url}` : ""}`,
            })
            .then(() => props.openTooltip("Shared successfully"))
            .catch((e) => {
              console.warn("Share failed: " + e);
            });
        } catch (e) {
          window.location.href =
            "mailto:?body=" +
            props.emailText +
            (props.url ? `%0D%0A${props.url}` : "");
          props.openTooltip("Opened in mail program");
        }
      }}
    >
      <ShareIcon fontSize="inherit" />
    </IconButton>
  );
};
