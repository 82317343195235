import { TextField, TextFieldProps } from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

export type FormTextFieldProps = {
  name: string;
  control: Control;
  label?: string | ReactNode;
  required?: boolean;
  disabled?: boolean;
  transform?: Transform<any, any>;
  additionalRules?: RegisterOptions;
  helperText?: string;
} & Partial<Omit<TextFieldProps, "onChange" | "value" | "name">>;

export default function FormTextField(props: FormTextFieldProps) {
  const {
    name,
    control,
    required,
    label,
    disabled,
    additionalRules,
    transform,
    helperText,
    ...remainingProps
  } = props;

  const inputProps = props.inputProps as Partial<StandardInputProps>;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required || false,
          message: "This is required",
        },
        ...additionalRules,
      }}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <TextField
          disabled={disabled}
          InputProps={{
            ...inputProps,
          }}
          variant={"standard"}
          required={required}
          label={label}
          error={!!error}
          helperText={error?.message || helperText}
          InputLabelProps={{ shrink: true }}
          margin={"normal"}
          onChange={(e) => {
            transform?.input
              ? onChange(transform.input(e.target.value))
              : onChange(e.target.value);
          }}
          value={transform?.output ? transform.output(value) : value}
          {...field}
          {...remainingProps}
        />
      )}
    />
  );
}

export interface Transform<A, B> {
  input?: (a: A) => B;
  output: (b: B) => A;
}
