import { Link } from "@mui/material";
import ShareColumn from "./table/ShareColumn";
import { useConfig } from "provider/ConfigProvider";
import { PublicKey } from "@solana/web3.js";

export function publicKeyToUrl(pubKey: string, cluster: string): string {
  return `https://solscan.io/account/${pubKey}?cluster=${cluster}`;
}

export function txKeyToUrl(pubKey: string, cluster: string): string {
  return `https://solscan.io/tx/${pubKey}?cluster=${cluster}`;
}

export function shortenPublicKey(publicKey: string | undefined): string {
  if (!publicKey) {
    return "unknown";
  }
  if (publicKey.length <= 8) {
    return publicKey;
  }
  return (
    publicKey.substring(0, 4) +
    "..." +
    publicKey.substring(publicKey.length - 4)
  );
}

export function PublicKeyLink(props: {
  pubKey: string | PublicKey;
  type: "transaction" | "address";
}) {
  const address =
    typeof props.pubKey === "string" ? props.pubKey : props.pubKey.toBase58();
  const { solanaCluster } = useConfig();
  const url =
    props.type === "address"
      ? publicKeyToUrl(address, solanaCluster)
      : txKeyToUrl(address, solanaCluster);
  const shortPubKey = shortenPublicKey(address);

  return (
    <ShareColumn
      value={address}
      emailText={"Mint request wallet key: " + address}
      content={
        <Link
          href={url}
          target="_blank"
          underline="hover"
          sx={{ fontFamily: "monospace" }}
        >
          {shortPubKey}
        </Link>
      }
    />
  );
}
