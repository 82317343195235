import { EventDate } from "types/event";
import { MembershipLevel } from "types/membership";
import { dateWithoutTimezone } from "./dateUtils";

export const getPublicEventUrl = (
  landingPageUrl: string,
  publicId: string,
  slug?: string | null
) => {
  return `${landingPageUrl}/en/events/${publicId}` + (slug ? `/${slug}` : "");
};

export const isEventPublished = (publishAt: string) =>
  publishAt && new Date(publishAt) <= new Date();

export const isEventScheduled = (publishAt: string) =>
  publishAt && new Date(publishAt) > new Date();

export const getKey2LevelGradient = (level: MembershipLevel): string => {
  let gradient = "";
  switch (level) {
    case MembershipLevel.SILVER:
      gradient = "#a1a1a1, #f4f4f4, #7a7a7a";
      break;
    case MembershipLevel.GOLD:
      gradient = "#c6a155, #faf4bc, #b58939";
      break;
    case MembershipLevel.ULTRA:
      gradient = "#a77044, #f4e2d2, #7a4b2b";
      break;
    case MembershipLevel.OBSIDIAN:
      gradient = "#000000, #777777, #000000";
      break;
  }
  return gradient;
};

export const getUtcEventDate = (eventDate: EventDate): EventDate => {
  return {
    fromDate: dateWithoutTimezone(eventDate.fromDate),
    toDate: dateWithoutTimezone(eventDate.toDate),
    timezone: eventDate.timezone,
  };
};
