import { Box } from "@mui/material";
import FormDateField from "components/form/FormDateField";
import FormSelectField from "components/form/FormSelectField";
import { useAxios } from "provider/AxiosProvider";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getTimezones } from "utils/api/EventApi";

export default function EventDate() {
  const { control, watch, setValue } = useFormContext();
  const fromDateWatch = watch("date.fromDate");
  const toDateWatch = watch("date.toDate");
  const axios = useAxios();
  const [timezones, setTimezones] = useState<string[]>();

  useEffect(() => {
    if (
      fromDateWatch &&
      toDateWatch &&
      new Date(fromDateWatch) > new Date(toDateWatch)
    ) {
      setValue("date.toDate", fromDateWatch);
    }
  }, [fromDateWatch, setValue, toDateWatch]);

  useEffect(() => {
    getTimezones(axios).then(setTimezones);
  }, [axios]);

  if (!timezones) return <></>;

  return (
    <Box display="flex" flexDirection="row" gap={4}>
      <FormDateField
        name="date.fromDate"
        control={control}
        label={"From"}
        required
        dateProps={{
          disablePast: true,
        }}
      />
      <FormDateField
        name="date.toDate"
        control={control}
        label={"To"}
        required
        dateProps={{
          // FIXME: (doesn't work as expected on iOS):
          // minDate: moment(fromDateWatch),
          disablePast: true,
        }}
      />
      <FormSelectField
        name="date.timezone"
        control={control}
        required
        label={"Timezone"}
        list={timezones}
      />
    </Box>
  );
}
