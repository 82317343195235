import { Typography, Stack, Box } from "@mui/material";
import { RefreshButton } from "@refinedev/mui";
import LoadingScreen from "components/layout/LoadingScreen";
import LogoutButton from "components/layout/LogoutButton";
import React from "react";

export default function UnexpectedError(props: { loading: boolean }) {
  const refreshPage = () => window.location.reload();

  return (
    <LoadingScreen loading={props.loading}>
      <Typography variant="h1">500</Typography>
      <Stack direction="row" spacing="2">
        <Typography>Sorry, an unexpected error occured.</Typography>
      </Stack>
      <Box>
        <RefreshButton onClick={refreshPage} />
        <LogoutButton />
      </Box>
    </LoadingScreen>
  );
}
