import { Chip } from "@mui/material";
import React from "react";
import { TransactionStatus } from "types/transaction";

export default function TransactionStatusChip(props: {
  status: TransactionStatus;
}) {
  const { status } = props;

  return (
    <Chip
      color={
        status === TransactionStatus.SUCCEEDED
          ? "success"
          : status === TransactionStatus.EXPIRED
          ? "error"
          : status === TransactionStatus.OPEN
          ? "info"
          : "default"
      }
      label={status}
      variant="outlined"
    />
  );
}
