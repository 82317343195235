import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useConfig } from "provider/ConfigProvider";
import { useCallback, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// https://stackoverflow.com/a/69489231
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function EventMap() {
  const { setValue, watch, getValues } = useFormContext();
  const { mapboxKey } = useConfig();

  const marker = useRef<mapboxgl.Marker>();
  const map = useRef<mapboxgl.Map>();

  mapboxgl.accessToken = mapboxKey;

  const setLocation = useCallback(
    (lat: number, lng: number) => {
      setValue("location.geoLoc", { lat, lng });
    },
    [setValue]
  );

  useEffect(() => {
    // Map Search Input
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Address or place name",
      marker: false,
      flyTo: false,
    }).on("result", (e) => {
      const { context, center, properties, place_name } = e.result;
      const city = context.find((c: any) => c.id.includes("place"));
      const zip = context.find((c: any) => c.id.includes("postcode"));
      const country = context.find((c: any) => c.id.includes("country"));
      const region = context.find((c: any) => c.id.includes("region"));
      setValue("location.streetAddress", properties.address || place_name);
      city && setValue("location.city", city.text);
      zip && setValue("location.zip", zip.text);
      country && setValue("location.country", country.text);
      region && setValue("location.region", region.text);
      setLocation(center[1], center[0]);
    });

    // Map
    map.current = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      center: [10.991928, 49.440047],
      zoom: 4,
    })
      .on("click", (e) => {
        const { lngLat } = e;
        setLocation(lngLat.lat, lngLat.lng);
      })
      .addControl(geocoder, "top-left")
      .addControl(new mapboxgl.NavigationControl(), "top-right");

    return () => {
      map.current?.remove();
    };
  }, [setLocation, setValue]);

  const addMarker = useCallback(
    (center: [number, number]) => {
      marker.current =
        map.current &&
        new mapboxgl.Marker({ draggable: true })
          .setLngLat(center)
          .addTo(map.current)
          .on("dragend", () => {
            const lngLat = marker.current!!.getLngLat();
            setLocation(lngLat.lat, lngLat.lng);
          });
    },
    [setLocation]
  );

  const setNewMapPoint = useCallback(
    (lat: number, lng: number) => {
      marker.current?.remove();
      if (lat && lng) {
        map.current?.flyTo({
          center: [lng, lat],
          essential: true,
          zoom: 13,
          animate: false,
        });
        addMarker([lng, lat]);
      } else {
        // Reset map
        map.current?.flyTo({
          zoom: 4,
          essential: true,
          animate: false,
        });
      }
    },
    [addMarker]
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.includes("location.geoLoc")) {
        setNewMapPoint(value.location.geoLoc.lat, value.location.geoLoc.lng);
      }
    });
    return () => subscription.unsubscribe();
  }, [addMarker, setNewMapPoint, watch]);

  useEffect(() => {
    setNewMapPoint(
      getValues("location.geoLoc.lat"),
      getValues("location.geoLoc.lng")
    );
  });

  return <div id="map" style={{ width: "100%", height: "100%" }} />;
}
