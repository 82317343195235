import { useAxios } from "provider/AxiosProvider";
import React, { useEffect, useState } from "react";
import { getFile } from "utils/api/FileApi";

export function RedemptionImage(props: { fileId?: string | null }) {
  const axios = useAxios();
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    if (props.fileId)
      getFile(axios, props.fileId).then((file: File) => {
        setFileUrl(URL.createObjectURL(file));
      });
  }, [props.fileId, axios]);

  return fileUrl !== "" ? (
    <img style={{ height: 50 }} src={fileUrl} alt="Redemption" />
  ) : (
    <></>
  );
}
