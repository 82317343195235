import { AxiosInstance } from "axios";
import { apiHost } from "utils/rest/restApi";

const getQrCode = async (axios: AxiosInstance, url: string): Promise<File> => {
  const response = await axios({
    responseType: "blob",
    method: "get",
    url: apiHost + "/qr-code?url=" + url,
  });

  return response.data;
};

export const downloadQrCode = (
  axios: AxiosInstance,
  url: string,
  fileName?: string
) => {
  getQrCode(axios, url).then((file) => {
    // Bravely copied from https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios#53230807
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName || "qr-code.png");
    document.body.appendChild(link);
    link.click();
  });
};
