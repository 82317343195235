import { Membership } from "../../types/membership";
import React, { useState } from "react";
import { OpenInSolanaExplorerButton } from "../OpenInSolanaExplorerButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Grid, IconButton, Tooltip } from "@mui/material";

export function MembershipCell(props: {
  membership: Membership;
  mintAddress: string;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("Copied to clipboard");

  const handleTooltip = (text: string) => {
    setTooltipTitle(text);
    setShowTooltip(true);
  };
  return (
    <Grid container gap={1}>
      <OpenInSolanaExplorerButton pubKey={props.mintAddress} type="address">
        <img
          style={{ height: 50 }}
          src={props.membership.imageUrl}
          alt="membership-nft"
        />
      </OpenInSolanaExplorerButton>
      <Tooltip
        title={tooltipTitle}
        open={showTooltip}
        leaveDelay={1000}
        onClose={() => setShowTooltip(false)}
      >
        <IconButton
          size="small"
          onClick={async (event) => {
            event.stopPropagation();
            await navigator.clipboard.writeText(props.mintAddress);
            handleTooltip("Copied to clipboard");
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
