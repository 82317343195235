import { Grid, Typography } from "@mui/material";
import FormTextField from "components/form/FormTextField";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { DateAndLocationSection } from "../DateAndLocationSection";
import { TagSection } from "../TagSection";

export default function BasicPage() {
  const { control, watch, setValue } = useFormContext();

  const title = watch("title");

  useEffect(() => {
    if (title) {
      setValue("slug", title.toLowerCase().replace(/\s+/g, "-"));
    }
  }, [setValue, title]);

  return (
    <Grid container flexDirection={"column"} spacing={6}>
      <Grid item flexDirection={"column"} display="flex">
        <FormTextField
          name="title"
          control={control}
          label={"Title"}
          required
        />
        <FormTextField
          name="slug"
          control={control}
          label={"Slug"}
          InputProps={{
            startAdornment: (
              <Typography
                variant="body2"
                marginRight={0.3}
                sx={{ fontStyle: "italic" }}
              >
                /
              </Typography>
            ),
            sx: {
              fontStyle: "italic",
            },
          }}
          transform={{
            input: (o: string) => {
              if (!o) return "";
              // Replace space with dash and remove slashes
              return o.toLowerCase().replace(/\s+/g, "-").replace(/\//g, "");
            },
            output: (s: any) => s,
          }}
          additionalRules={{
            pattern: {
              value: /^[a-z0-9-]+$/,
              message: "Invalid slug",
            },
          }}
        />
      </Grid>
      <Grid item>
        <TagSection />
      </Grid>
      <Grid item>
        <DateAndLocationSection />
      </Grid>
    </Grid>
  );
}
