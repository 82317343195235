import { MenuItem } from "@mui/material";
import { useNavigation } from "@refinedev/core";
import { EditButton } from "@refinedev/mui";

export default function EditMenuItem(props: {
  resource: string;
  id: string | number;
  disabled?: boolean;
}) {
  const { edit } = useNavigation();

  return (
    <MenuItem
      onClick={() => edit(props.resource, props.id)}
      disabled={props.disabled}
    >
      <EditButton size="small" />
    </MenuItem>
  );
}
