import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Membership } from "types/membership";
import { getMemberships } from "utils/api/MembershipApi";
import { useAxios } from "./AxiosProvider";
import { useBranch } from "./BranchProvider";

export const MembershipContext = createContext<Membership[]>({} as any);

export const MembershipProvider = (props: { children: ReactNode }) => {
  const branch = useBranch();
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const axios = useAxios();

  useEffect(() => {
    getMemberships(axios, branch?.id).then(setMemberships);
  }, [axios, branch]);

  return (
    <MembershipContext.Provider value={memberships}>
      {props.children}
    </MembershipContext.Provider>
  );
};

export function useMemberships(): Membership[] {
  return useContext(MembershipContext);
}
