import { DataGrid } from "@mui/x-data-grid";
import { List, useDataGrid } from "@refinedev/mui";
import { useBranch } from "provider/BranchProvider";
import { checkoutCols } from "tables/checkoutCols";
import { Checkout } from "types/checkout";

export default function CheckoutList() {
  const branch = useBranch();
  const { dataGridProps } = useDataGrid<Checkout>({
    queryOptions: { refetchInterval: 3000, refetchIntervalInBackground: false },
    filters: {
      permanent: [{ field: "branchId", operator: "eq", value: branch?.id }],
    },
  });

  return (
    <List headerButtons={<></>}>
      <DataGrid
        columns={checkoutCols}
        {...dataGridProps}
        autoHeight
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
      />
    </List>
  );
}
