import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from "react-image-crop";

export default function ImageCropDialog(props: {
  open: boolean;
  handleCropComplete: (file?: File) => void;
  file?: File;
}) {
  const { open, handleCropComplete, file } = props;
  const [crop, setCrop] = useState<Crop>();
  const [imageSrc, setImageSrc] = useState<string>();
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!file) return;
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      setImageSrc(img.src);
      const crop = centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 100,
          },
          1,
          img.width,
          img.height
        ),
        img.width,
        img.height
      );
      setCrop(crop);
    };
  }, [file]);

  const handleClose = () => {
    handleCropComplete();
  };

  const handleSave = () => {
    if (imgRef.current && crop) {
      makeClientCrop(imgRef.current, crop);
    } else {
      handleClose();
    }
  };

  const makeClientCrop = async (img: HTMLImageElement, crop: Crop) => {
    const croppedImageUrl = await getCroppedImg(img, crop);
    handleCropComplete(croppedImageUrl as File);
  };

  const getCroppedImg = (
    image: HTMLImageElement,
    croppedArea: Crop
  ): Promise<Blob | null> => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / 100; // Scale factor for width
    const scaleY = image.naturalHeight / 100; // Scale factor for height
    const width = croppedArea.width * scaleX;
    const height = croppedArea.height * scaleY;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (!ctx) return Promise.resolve(null);

    ctx.drawImage(
      image,
      croppedArea.x * scaleX,
      croppedArea.y * scaleY,
      width,
      height,
      0,
      0,
      width,
      height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        // @ts-ignore
        blob.name = file?.name;
        resolve(blob);
      }, file?.type ?? "image/jpeg");
    });
  };

  return (
    <Dialog
      open={open && imageSrc !== undefined}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          keepSelection={true}
          aspect={1}
        >
          <img src={imageSrc} ref={imgRef} alt="crop" />
        </ReactCrop>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
