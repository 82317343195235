import { useMediaQuery } from "@mui/material";
import { DataGrid, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { CreateButton, List, useDataGrid } from "@refinedev/mui";
import { CustomToolbar } from "components/table/CustomToolbar";
import { useBranch } from "provider/BranchProvider";
import { useMemberships } from "provider/MembershipProvider";
import React, { useEffect } from "react";
import { mintCols } from "tables/mintCols";
import { MintRequest } from "types/mint-request";
import MintRequestFilter from "./MintRequestFilter";

export default function MintRequestList() {
  const branch = useBranch();
  const memberships = useMemberships();

  const { dataGridProps, setFilters, filters } = useDataGrid<MintRequest>({
    queryOptions: { refetchInterval: 3000, refetchIntervalInBackground: false },
    filters: {
      permanent: [{ field: "branchId", operator: "eq", value: branch?.id }],
    },
  });

  const matches = useMediaQuery("(min-width:700px)");

  const [columnVisible, setColumnVisible] =
    React.useState<GridColumnVisibilityModel>({
      id: true,
      status: true,
      createdAt: true,
      createdBy: true,
      amountOfCents: true,
      walletKey: true,
    });

  useEffect(() => {
    const newColumns = matches
      ? {
          ...columnVisible,
          id: true,
          createdBy: true,
          walletKey: true,
        }
      : {
          ...columnVisible,
          id: false,
          createdBy: false,
          walletKey: false,
        };
    setColumnVisible(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  return (
    <List
      headerButtons={
        branch && memberships.length > 0 ? (
          <CreateButton>Create Mint Request</CreateButton>
        ) : (
          <></>
        )
      }
    >
      <MintRequestFilter
        setFilters={setFilters}
        filters={filters}
        exportDisabled={dataGridProps.rowCount === 0}
      />
      <DataGrid
        {...dataGridProps}
        columns={mintCols}
        autoHeight
        columnVisibilityModel={columnVisible}
        slots={{ toolbar: CustomToolbar }}
        onColumnVisibilityModelChange={(newModel) => setColumnVisible(newModel)}
      />
    </List>
  );
}
