import { Button } from "@mui/material";
import { useConfig } from "provider/ConfigProvider";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export default function EventPreview() {
  const { getValues } = useFormContext();
  const { landingPageUrl } = useConfig();

  const [previews, setPreviews] = useState<Window[]>([]);

  function handleButtonClick() {
    const popup = window.open(landingPageUrl + "/en/event-preview/", "_blank");
    if (popup) {
      setPreviews([...previews, popup]);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      for (const preview of previews) {
        if (preview.closed) {
          setPreviews((prev) => prev.filter((p) => p !== preview));
        } else {
          preview.postMessage(
            {
              type: "key2-event",
              data: getValues(),
            },
            "*"
          );
        }
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [getValues, previews]);

  return (
    <div>
      <Button variant="outlined" onClick={handleButtonClick}>
        Preview Event on Website
      </Button>
    </div>
  );
}
