import { GridColDef } from "@mui/x-data-grid";
import { PublicKeyLink } from "components/PublicKeyLink";
import { EntryStatus, EventEntry } from "types/entry";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Chip } from "@mui/material";

export const entryCols: GridColDef<EventEntry>[] = [
  {
    field: "id",
    headerName: "Entry Id",
    flex: 1,
  },
  {
    field: "guestCount",
    headerName: "Guest Count",
    renderCell: function render({ row }) {
      return row.guestCount || "-";
    },
    flex: 1,
    type: "number",
  },
  {
    field: "walletKey",
    headerName: "Wallet Key",
    flex: 1,
    renderCell: function render({ row }) {
      return <PublicKeyLink pubKey={row.walletKey} type="address" />;
    },
  },
  {
    field: "levelSufficient",
    headerName: "Level Sufficient",
    flex: 1,
    renderCell: function render({ row }) {
      return row.levelSufficient ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      );
    },
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    flex: 1,
    renderCell: function render({ row }) {
      return <span>{new Date(row.timestamp).toLocaleString()}</span>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Chip
          color={
            row.status === EntryStatus.PERFORMED
              ? "success"
              : row.status === EntryStatus.OPEN
              ? "info"
              : "default"
          }
          label={row.status}
          variant="outlined"
        />
      );
    },
  },
];
