import { AxiosError, AxiosResponse } from "axios";

export function handleSuccessResponse(response: AxiosResponse): Promise<any> {
  return Promise.resolve(response.data);
}

export function handleErrorResponse(
  error: AxiosError
): Promise<ValidationErrorResponse> {
  if (
    error.response &&
    (error.response.status === 400 || error.response.status === 422)
  ) {
    if (error.response.data) {
      return Promise.reject(error.response as ValidationErrorResponse);
    } else {
      throw new Error(
        "An unexpected error occured: " + JSON.stringify(error.response.data)
      );
    }
  } else {
    throw new Error(error.message);
  }
}

export interface FieldError {
  field: string;
  code: string;
  arguments: [string, string | number][];
  defaultMessage: string;
}

export interface ObjectError {
  objectName: string;
  code: string;
  arguments: [string, string | number][];
  defaultMessage: string;
}

export interface ValidationErrors {
  fieldErrors: FieldError[];
  globalErrors: ObjectError[];
}

export interface ValidationErrorResponse extends AxiosResponse {
  data: ValidationErrors;
}
