import { CircularProgress } from "@mui/material";
import { useAxios } from "provider/AxiosProvider";
import { useRef, useEffect } from "react";
import { UploadedFile, uploadFile } from "utils/api/FileApi";

export function ImageUpload(props: {
  fileToUpload: File;
  onUploadComplete: (uploadedFile: UploadedFile) => void;
}) {
  const axios = useAxios();
  const { fileToUpload, onUploadComplete } = props;
  const uploading = useRef(false);
  useEffect(() => {
    if (uploading.current) return;
    uploading.current = true;
    uploadFile(axios, fileToUpload).then((uploadedFile) => {
      onUploadComplete(uploadedFile);
    });
  }, [axios, uploading, fileToUpload, onUploadComplete]);
  return <CircularProgress />;
}
