import { Box } from "@mui/material";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import MembershipToggle from "components/form/MembershipToggle";
import { useMemberships } from "provider/MembershipProvider";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import {
  ICreateMintRequest,
  defaultMintRequest,
  ofCreateMintRequest,
} from "types/mint-request";

export default function CreateMintRequest() {
  const memberships = useMemberships();

  const methods = useForm<ICreateMintRequest>({
    defaultValues: defaultMintRequest,
  });

  const { control, getValues, watch, setValue } = methods;

  const membershipId = watch("membershipId");
  const amountOfCents = memberships.find(
    (m) => m.id === membershipId
  )?.amountOfCents;

  useEffect(() => {
    const membershipId = getValues("membershipId");
    if (!membershipId && memberships.length > 0) {
      setValue("membershipId", memberships[0].id);
    }
  }, [memberships, getValues, setValue]);

  return (
    <Create saveButtonProps={methods.saveButtonProps}>
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <MembershipToggle name="membershipId" control={control} />

          <FormTextField
            required
            control={control}
            name={ofCreateMintRequest("walletKey")}
            label="Wallet Key"
            fullWidth
          />
          <FormTextField
            control={control}
            name={ofCreateMintRequest("amountOfCents")}
            label="K2T Amount"
            helperText={
              "Leave blank to use the default amount of this membership." +
              (amountOfCents !== undefined ? ` (${amountOfCents!! / 100})` : "")
            }
            type="number"
            fullWidth
            additionalRules={{
              min: 0,
            }}
            transform={{
              input: (v: any) => {
                if (!v) return v;
                return v * 100;
              },
              output: (v: any) => {
                if (!v) return v;
                return v / 100;
              },
            }}
          />
          <FormTextField
            control={control}
            name={ofCreateMintRequest("serial")}
            label="Serial Number"
            type="number"
            fullWidth
            helperText={"Leave empty to use the next Serial number"}
          />
        </Box>
      </FormProvider>
    </Create>
  );
}
