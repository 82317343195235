import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Avatar,
  Button,
  Popover,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LogoutButton from "../LogoutButton";
import BranchSelect from "./BranchSelect";
import { useLocation } from "react-router-dom";

export default function Header() {
  const { user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const branchPicker = useRef<HTMLInputElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.match(/branches|info|k2t-stats|users|coupons/) &&
      branchPicker.current
    ) {
      branchPicker.current.style.visibility = "hidden";
    } else if (branchPicker.current) {
      branchPicker.current.style.visibility = "visible";
    }
  }, [location]);

  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            ref={branchPicker}
            direction="row"
            display="flex"
            flexDirection="column"
            minWidth="200px"
          >
            <BranchSelect />
          </Stack>
          {user && (
            <Stack
              display="flex"
              flexDirection="row"
              direction="row"
              alignContent="center"
              spacing={1}
            >
              <Typography alignSelf="center" variant="subtitle2">
                {user.email}
              </Typography>
              <Button onClick={handleClick}>
                <Avatar src={user.picture} alt={user?.name} />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <LogoutButton />
              </Popover>
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
