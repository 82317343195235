import {
  Metaplex,
  Nft,
  NftWithToken,
  Sft,
  SftWithToken,
} from "@metaplex-foundation/js";
import { PublicKey } from "@solana/web3.js";

export const getToken = (
  metaplex: Metaplex,
  mintAddress: string
): Promise<Sft | SftWithToken | Nft | NftWithToken> => {
  return metaplex
    .nfts()
    .findByMint({ mintAddress: new PublicKey(mintAddress) });
};
