import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import React from "react";

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
