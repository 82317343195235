import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { HttpError } from "@refinedev/core";
import { Edit, SaveButton } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import { FormProvider } from "react-hook-form";
import { Membership, defaultMembership } from "types/membership";

export default function EditMembership() {
  const methods = useForm<Membership, HttpError>({
    defaultValues: defaultMembership,
  });
  const {
    control,
    watch,
    handleSubmit,
    refineCore: { onFinish },
    setValue,
  } = methods;

  const handleSave = async (e: React.BaseSyntheticEvent) => {
    await handleSubmit((values) => {
      onFinish({
        ...values,
      });
    })(e);
  };

  return (
    <Edit
      footerButtons={() => (
        <SaveButton onClick={(e: React.BaseSyntheticEvent) => handleSave(e)} />
      )}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <Box display={"flex"} gap={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={watch("priceInUsdCents") >= 100}
                  onClick={(e) =>
                    setValue(
                      "priceInUsdCents",
                      (e.target as any).checked ? 100 : ""
                    )
                  }
                />
              }
              label={"Purchaseable"}
            />
            <FormTextField
              name="priceInUsdCents"
              control={control}
              type="number"
              label="Price"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: "$" }}
              helperText={"The price of this membership in dollars."}
              fullWidth
              transform={{
                input: (v: any) => {
                  if (!v) return v;
                  return v * 100;
                },
                output: (v: any) => {
                  if (!v) return v;
                  return v / 100;
                },
              }}
              additionalRules={{
                validate: (v: number) =>
                  v < 0 || v >= 100
                    ? true
                    : "If set, price must be at least 1$",
              }}
            />
          </Box>
          <FormTextField
            name="amountOfCents"
            control={control}
            type="number"
            fullWidth
            label="K2T Amount"
            InputLabelProps={{ shrink: true }}
            required
            helperText={
              "The amount of tokens a member will receive with this membership."
            }
            transform={{
              input: (v: number) => v * 100,
              output: (v: number) => v / 100,
            }}
          />
        </Box>
      </FormProvider>
    </Edit>
  );
}
