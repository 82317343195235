import { DataGrid } from "@mui/x-data-grid";
import { List } from "@refinedev/mui";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ticketCols } from "tables/ticketCols";
import { Event, ofEvent } from "types/event";
import { Ticket } from "types/ticket";
import CreateTicketDialog from "../CreateTicketDialog";

export default function TicketsPage() {
  const { control } = useFormContext<Event>();
  const { fields, append, update } = useFieldArray({
    control,
    name: ofEvent("tickets"),
    keyName: "key",
  });

  return (
    <List
      title="Tickets"
      breadcrumb={<></>}
      headerButtons={
        <CreateTicketDialog setFormValue={(ticket: Ticket) => append(ticket)} />
      }
    >
      <DataGrid
        rows={fields.map((ticket, index) => ({ ...ticket, index: index }))}
        columns={ticketCols((ticket, index) => update(index, ticket))}
        autoHeight
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        hideFooterPagination
      />
    </List>
  );
}
