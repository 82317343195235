import { Grid, useTheme } from "@mui/material";
import { useWeb3 } from "provider/Web3Provider";

export default function CurrencyAmount(props: {
  /** Token amount, accounted for decimals.
   * This is often provided by web3js library. */
  uiAmount?: number | null;
  /** Token amount, in cents.
   * This is often provided by backend. */
  amountOfCents?: number | null;
}) {
  const { currencyImageUrl } = useWeb3();
  const theme = useTheme();

  const amount: null | number =
    props.uiAmount || (props.amountOfCents && props.amountOfCents / 100) || null;
  return (
    <Grid display="flex" alignItems="center">
      <img
        src={currencyImageUrl}
        alt="K2T-Coin"
        style={{
          width: theme.spacing(3),
          marginRight: theme.spacing(1),
        }}
      />
      <span style={{ alignSelf: "center" }}>
        {amount ? amount.toLocaleString("en-US") : "?"}
      </span>
    </Grid>
  );
}
