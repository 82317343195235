import { GridColDef } from "@mui/x-data-grid";
import { PublicKeyLink } from "components/PublicKeyLink";
import CurrencyAmount from "components/CurrencyAmount";
import { TokenAccountBalancePairWithRank } from "pages/k2t-stats/K2TStatsPage";

export const statCols = (
  supply: number
): GridColDef<TokenAccountBalancePairWithRank>[] => [
  {
    field: "rank",
    headerName: "Rank",
    flex: 1,
    minWidth: 50,
    maxWidth: 100,
    type: "number",
  },
  {
    field: "uiAmount",
    headerName: "Balance",
    flex: 1,
    renderCell: function render({ row }) {
      return row.uiAmount ? (
        <CurrencyAmount uiAmount={row.uiAmount} />
      ) : (
        <></>
      );
    },
  },
  {
    field: "percent",
    headerName: "% of Supply",
    flex: 1,
    minWidth: 100,
    type: "number",
    sortComparator: (r1, r2) => {
      return (r1?.row?.uiAmount || 0) - (r2?.row?.uiAmount || 0);
    },
    renderCell: function render({ row }) {
      const percent = (100.0 * (row.uiAmount || 0)) / supply;
      return percent.toFixed(2) + " %";
    },
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
    minWidth: 100,
    renderCell: function render({ row }) {
      return <PublicKeyLink pubKey={row.address} type="address" />;
    },
  },
];
