import { Box, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import FormTextField from "../../../components/form/FormTextField";
import EventDate from "./EventDate";
import EventMap from "./EventMap";

export function DateAndLocationSection() {
  const { control } = useFormContext();

  return (
    <div>
      <Typography variant="h6">Date and Location</Typography>
      <EventDate />
      <Grid container display="flex" flexDirection={"row"} marginTop={1}>
        <Grid item md={12} lg={5} paddingRight={4}>
          <Box>
            <FormTextField
              name="location.streetAddress"
              control={control}
              label={"Street"}
              fullWidth
            />
            <Box display="flex" flexDirection="row" gap={4}>
              <FormTextField
                name="location.zip"
                control={control}
                label={"Zip"}
                fullWidth
              />
              <FormTextField
                name="location.city"
                control={control}
                label={"City"}
                fullWidth
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={4}>
              <FormTextField
                name="location.region"
                control={control}
                label={"Region"}
                fullWidth
              />
              <FormTextField
                name="location.country"
                control={control}
                label={"Country"}
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" gap={4}>
            <FormTextField
              control={control}
              name="location.geoLoc.lat"
              label="Latitude"
              type="number"
            />
            <FormTextField
              control={control}
              name="location.geoLoc.lng"
              label="Longitude"
              type="number"
            />
          </Box>
        </Grid>
        <Grid item md={12} lg={7} height={"300px"}>
          <EventMap />
        </Grid>
      </Grid>
    </div>
  );
}
