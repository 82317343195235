import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LightTheme } from "@refinedev/mui";
import RefineWrapper from "RefineWrapper";
import { AxiosProvider } from "provider/AxiosProvider";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <ThemeProvider
      theme={{
        ...LightTheme,
        palette: {
          ...LightTheme.palette,
          primary: {
            main: "#D2B369",
            contrastText: "#FFF",
          },
          secondary: {
            main: "#111",
            contrastText: "#EEE",
          },
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <AxiosProvider>
          <BrowserRouter>
            <RefineWrapper />
          </BrowserRouter>
        </AxiosProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
