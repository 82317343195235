import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TagIcon from "@mui/icons-material/Tag";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { EditButton } from "@refinedev/mui";
import ShareColumn from "components/table/ShareColumn";
import { useConfig } from "provider/ConfigProvider";
import { Event } from "types/event";
import {
  getKey2LevelGradient,
  getPublicEventUrl,
  isEventPublished,
  isEventScheduled,
} from "utils/eventUtils";
import { EventMenu } from "./EventMenu";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { useBranchContext } from "provider/BranchProvider";
import ApartmentIcon from "@mui/icons-material/Apartment";

export default function EventCard(props: { event: Event }) {
  const { event } = props;
  const highlightImage = event?.images?.find((i) => i.highlight);
  const { staffAppUrl, landingPageUrl } = useConfig();
  const theme = useTheme();
  const { branch, branches } = useBranchContext();

  return (
    <Card>
      <Box position={"relative"}>
        {event.images && event.images.length > 0 ? (
          <CardMedia
            component="img"
            alt="Event Banner"
            height="200px"
            image={highlightImage ? highlightImage.url : event.images[0].url}
            sx={{ zIndex: 0 }}
          />
        ) : (
          <Skeleton variant="rectangular" height={200} animation={false} />
        )}
        {event.minKey2Level && (
          <Chip
            label={event.minKey2Level}
            sx={{
              position: "absolute",
              top: theme.spacing(1),
              left: theme.spacing(1),
              background: `linear-gradient(to top left, ${getKey2LevelGradient(
                event.minKey2Level
              )})`,
              color: "black",
            }}
          />
        )}
        <EventMenu event={event} />
      </Box>
      <CardContent>
        <Grid container spacing={3}>
          <Grid container item direction={"column"}>
            <Grid container item>
              <Typography gutterBottom variant="h6" noWrap overflow={"hidden"}>
                {event.title}
              </Typography>
            </Grid>
            <Grid display="flex" alignItems={"center"} gap={1}>
              {event.draft ? (
                <Chip
                  label={"Draft"}
                  variant="outlined"
                  sx={{ borderRadius: "0" }}
                />
              ) : event.publishAt ? (
                isEventScheduled(event.publishAt) ? (
                  <Chip
                    label={"Scheduled"}
                    variant="outlined"
                    sx={{ borderRadius: "0" }}
                  />
                ) : (
                  <Chip
                    label={"Published"}
                    color="primary"
                    variant="filled"
                    sx={{ borderRadius: "0" }}
                  />
                )
              ) : (
                ""
              )}
              {event.private && (
                <Chip
                  label={"Private"}
                  variant="outlined"
                  sx={{ borderRadius: "0" }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container item gap={1}>
            <Grid container item display="flex" gap={2} alignItems={"center"}>
              <QrCodeIcon />
              {event.code && (
                <ShareColumn
                  value={event.code}
                  emailText={`Join my event on Key2: ${event.code}`}
                  url={`${staffAppUrl}://event?code=${event.code}`}
                />
              )}
            </Grid>
            <Grid
              container
              item
              gap={2}
              alignItems={"center"}
              flexWrap={"nowrap"}
            >
              <LocationOnIcon />
              <Typography
                variant="body2"
                color="text.secondary"
                overflow={"hidden"}
                noWrap
              >
                {event.location?.streetAddress || "-"}
              </Typography>
            </Grid>
            <Grid container item gap={2} alignItems={"center"}>
              <CalendarMonthIcon />
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {new Date(event.date.fromDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="primary">
                  {new Date(event.date.fromDate).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.primary">
                  -
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {new Date(event.date.toDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="primary">
                  {new Date(event.date.toDate).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              gap={2}
              alignItems={"center"}
              flexWrap={"nowrap"}
            >
              <TagIcon />
              <Typography
                variant="body2"
                color="text.secondary"
                overflow={"hidden"}
                noWrap
              >
                {event.tags && event.tags.length > 0
                  ? event.tags.map((tag, i) => (
                      <span key={i}>{`${tag}${
                        i !== event.tags!!.length - 1 ? ", " : ""
                      }`}</span>
                    ))
                  : "-"}
              </Typography>
            </Grid>
            <Grid
              container
              item
              gap={2}
              alignItems={"center"}
              flexWrap={"nowrap"}
            >
              <ConfirmationNumberIcon />
              <Typography
                variant="body2"
                color="text.secondary"
                overflow={"hidden"}
                noWrap
              >
                {`${event.tickets.length} Ticket${
                  event.tickets.length !== 1 ? "s" : ""
                }`}
              </Typography>
            </Grid>
            {!branch && (
              <Grid
                container
                item
                gap={2}
                alignItems={"center"}
                flexWrap={"nowrap"}
              >
                <ApartmentIcon />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  overflow={"hidden"}
                  noWrap
                >
                  {branches.find((b) => b.id === event.branchId)?.name}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justifyContent={"space-between"}>
          {!event.draft &&
            event.publishAt &&
            isEventPublished(event.publishAt) && (
              <Grid item>
                <ShareColumn
                  emailText={"Join my event on Key2:"}
                  url={getPublicEventUrl(
                    landingPageUrl,
                    event.publicId!!,
                    event.slug
                  )}
                />
              </Grid>
            )}
        </Grid>
        <Grid item>
          <EditButton recordItemId={event.id} />
        </Grid>
      </CardActions>
    </Card>
  );
}

export const EventCardSkeleton = () => {
  return (
    <Card>
      <Box position={"relative"}>
        <Skeleton variant="rectangular" height={200} animation={false} />
      </Box>
      <CardContent>
        <Grid container spacing={3}>
          <Grid container item direction={"column"}>
            <Grid container item>
              <Typography gutterBottom variant="h6" width="100%">
                <Skeleton />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item gap={1}>
            <Typography variant="body2" color="text.secondary" width={"60%"}>
              <Skeleton />
            </Typography>
            <Typography variant="body2" color="text.secondary" width={"60%"}>
              <Skeleton />
            </Typography>
            <Typography variant="body2" color="text.secondary" width={"70%"}>
              <Skeleton />
            </Typography>
            <Typography variant="body2" color="text.secondary" width={"60%"}>
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justifyContent={"flex-end"}>
          <Typography variant="body2" color="text.secondary" width={"30%"}>
            <Skeleton />
          </Typography>
        </Grid>
      </CardActions>
    </Card>
  );
};
